import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { signInSuccess } from '../Redux/user/userSlice.js'
import { FaArrowDownLong, FaPlus } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { PiLinkSimpleBold } from "react-icons/pi";
import { MdDraw } from "react-icons/md";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import uiux2 from '../assets/uiux2.json';
import { Alert, Spinner } from "flowbite-react";

function UiuCustomUiuxDesignsxTest() {

    const { currentUser } = useSelector((state) => state.user);

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isAttachLinks, setIsAttachLinks] = useState(false);
    const [links, setLinks] = useState([]);
    const [currentLink, setCurrentLink] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const dispatch = useDispatch();

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (currentLink.trim()) {
            setLinks([...links, currentLink]);
            setCurrentLink("");
        }
    };


    // Function to remove a link from the array
    const handleRemove = (index) => {
        setLinks(links.filter((_, i) => i !== index));
    };

    const truncateLink = (link, maxLength = 50) => {
        return link.length > maxLength ? link.substring(0, maxLength) + "..." : link;
    };


    //------------------- Handle submit Links and send Mail ---------------------
    const handleSubmitLinks = async () => {
        try {
            setLoading(true);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/design-links/${currentUser._id}`, {
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify({ links: links, email: currentUser.email })
            });

            const data = await res.json();
            if (res.status === 200) {
                setLoading(false);
                setSuccessMessage('Design links updated successfully and waiting to review by team.')
                dispatch(signInSuccess(data.user));
                setIsSubmitted(true);
            } else {
                setSuccessMessage('');
                setLoading(false);
                setErrorMessage(data.message ? data.message : 'Internal server error. Try again later.')
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        if (currentUser.userInfo?.designLinks?.length > 0) {
            setLinks([...currentUser.userInfo?.designLinks]);
        }
    }, [])


    useEffect(() => {
        // Register GSAP ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);

        // Select all elements with the 'about-us' class
        const popUpwardsSections = document.querySelectorAll(".pop-upwards");
        const leftToRightSections = document.querySelectorAll(".left-to-right");
        const rightToLeftSections = document.querySelectorAll(".right-to-left");
        const rotateClockwiseSections = document.querySelectorAll(".rotate-clockwaise");
        const flip360Sections = document.querySelectorAll(".flip-360");

        popUpwardsSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, y: 40 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.3,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 88%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        leftToRightSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, x: -40 },
                {
                    opacity: 1,
                    x: 0,
                    duration: 1.2,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 90%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        rightToLeftSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, x: 40 },
                {
                    opacity: 1,
                    x: 0,
                    duration: 1.2,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 88%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        rotateClockwiseSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, rotation: 0 },
                {
                    opacity: 1,
                    rotation: 90,
                    duration: 1.5,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 80%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        flip360Sections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, rotationY: 0 },
                {
                    opacity: 1,
                    rotationY: 360, // Flip 360 degrees
                    duration: 2.5,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 90%",
                        toggleActions: "play none none none",
                        once: false,
                    },
                    delay: index * 0.8,
                    repeat: -1,
                    repeatDelay: 3
                }
            );
        });
    }, []);

    return (
        <>
            <div className='w-full'>
                <div className='mx-auto max-w-[1140px] text-zinc-800 mt-12 p-5 flex justify-center gap-4 items-center'>
                    <h1 className='text-2xl font-semibold text-center'>Custom Designs</h1>
                    <MdDraw size={45} />
                </div>
                <div className='w-full max-w-[1140px] mx-auto border border-[#161c6f] rounded-2xl'>
                    <div className="p-14 flex flex-row items-start justify-between mt-2 mx-auto ">
                        <div>
                            <Lottie className='h-48 left-to-right' animationData={uiux2} ></Lottie>
                        </div>

                        <div className='flex flex-col justify-center w-1/2'>
                            <p className='text-xs text-gray-800 right-to-left'>
                                Feel free to share designs, prototypes, or links to any UI/UX tools that enhance creativity and collaboration. This can include Figma designs, Photoshop mockups, CodeSandbox projects, or other resources essential for creating intuitive user interfaces and experiences. These tools streamline workflows and bring innovative ideas to life efficiently.
                                <br />
                                <br />
                                <span className='text-gray-500'>These works will be visible to recruiters, showcasing your creativity and skills, significantly enhancing your chances of getting placed in your desired role.</span>
                            </p>

                            <button
                                onClick={() => setIsAttachLinks(!isAttachLinks)}
                                className="w-40 group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800 hover:text-white flex justify-between mt-6 pop-upwards"
                            >
                                <p>Attatch links</p>
                                <FaArrowDownLong

                                    size={20}
                                    className={`text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1 ${isAttachLinks && 'rotate-180'}`}
                                />
                            </button>
                        </div>
                    </div>

                    {isAttachLinks && (
                        <div className="px-16 py-10 mx-16 my-10 flex flex-col items-center bg-[#fefbf3] rounded-xl shadow-lg">
                            <div className="flex justify-center items-center gap-2 text-zinc-700">
                                <PiLinkSimpleBold size={25} />
                                <h1 className="font-semibold text-xl ">Attatch Links</h1>
                            </div>

                            {isSubmitted && successMessage !== '' ? (<Alert className='text-[12px]' color="success" icon={IoCheckmarkDoneCircleOutline}>{successMessage}</Alert>) : (
                                <div className="w-full flex flex-col items-center">
                                    <form onSubmit={handleSubmit} className="mt-5 w-full max-w-lg">
                                        <div className="flex justify-center items-center gap-3">
                                            <input
                                                type="url"
                                                value={currentLink}
                                                onChange={(e) => setCurrentLink(e.target.value)}
                                                placeholder="Enter a link"
                                                className="w-full px-4 py-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                required
                                            />
                                            <button
                                                type="submit"
                                                className="p-3  bg-blue-500 text-white hover:bg-blue-600 rounded-full"
                                            >
                                                <FaPlus />
                                            </button>
                                        </div>
                                    </form>

                                    {/* Display the list of links */}
                                    {links.length > 0 && (
                                        <div className="mt-5 w-full max-w-lg">
                                            <h2 className="text-lg font-medium mb-3">Added Links:</h2>
                                            <ul className="list-disc list-inside bg-white p-4 rounded-lg shadow">
                                                {links.map((link, index) => (
                                                    <li key={index} className="flex justify-between items-center text-blue-500 break-words py-2 px-6 rounded-lg bg-zinc-200 mb-2">
                                                        <a href={link} className="text-xs " target="_blank" rel="noopener noreferrer">
                                                            {truncateLink(link)}
                                                        </a>
                                                        <button
                                                            onClick={() => handleRemove(index)}
                                                            className="text-red-500 ml-3"
                                                        >
                                                            <AiOutlineClose size={18} />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    <button
                                        disabled={loading || isSubmitted}
                                        onClick={handleSubmitLinks}
                                        className="w-44 py-2 text-white text-sm bg-[#18b13c] hover:bg-[#227e38] rounded-md my-5"
                                    >
                                        {loading ? (
                                            <span className="flex gap-2 justify-center items-center">
                                                <Spinner size='sm' />
                                                <span className="text-sm">Submitting</span>
                                            </span>
                                        ) : 'Submit for Review'}
                                    </button>
                                </div>)}

                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default UiuCustomUiuxDesignsxTest;