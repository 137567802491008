import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ref, deleteObject, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.js";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { BsImageAlt } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Alert, Label, Spinner, Textarea, TextInput } from "flowbite-react";
import { RxCross1 } from "react-icons/rx";
import { FaCheck, FaLinkedin } from "react-icons/fa";
import { HiInformationCircle } from "react-icons/hi";
import { useSelector } from "react-redux";


export default function ViewBlog() {

    const categories = ['Java', 'Python', 'Data Analyst', 'Business Analyst', 'Business Intelligence', 'Web Development', 'Financial Analyst', 'C++', 'Data Scientist', 'Database Administrator',]

    const { currentUser } = useSelector((state) => state.user);

    const location = useLocation();
    let { authorId, title, content, image, category, createdAt, _id, linkedinProfile, isRequest } = location.state || {};

    const [loading, setLoading] = useState(false);
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [authorDetails, setAuthorDetails] = useState(null);
    const [formData, setFormData] = useState({
        authorId,
        title,
        content,
        image,
        category,
        linkedinProfile
    });


    const navigate = useNavigate();


    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures two-digit month
    const day = String(date.getDate()).padStart(2, '0'); // Ensures two-digit day


    //------------------------------ Get User Details on rendering --------------------------------------
    useEffect(() => {
        const getAuthorDetails = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/userId/${authorId}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                });
                const data = await res.json();
                if (res.status === 200) {
                    setAuthorDetails(data.user);
                }
            } catch (error) {
                console.log(error)
            }
        }

        getAuthorDetails();

    }, []);



    // ---------------------------------------- Handle input changes ----------------------------------------
    const handleChange = (e) => {
        const { id, value, files } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };


    //---------------------------------- handle Delete Blog & photo from Firebase --------------------------


    const handleDeleteBlog = async (downloadURL, blogId) => {
        try {
            const storagePath = decodeURIComponent(downloadURL.split("/o/")[1].split("?")[0]); // Extract path after "/o/" and before "?"


            const storageRef = ref(storage, storagePath);

            if (!isRequest) {
                // Deleting Blog from Blog Schema
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/blog/deleteBlog?blogId=${blogId}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" }
                });

                if (res.status === 200) {
                    await deleteObject(storageRef);
                    return navigate('/blog');
                } else {
                    setErrorMessage('Internal issue for deleting blog.')
                }

            } else {
                // Deleting Blog from Blog-Request Schema
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/blogRequest/deleteBlog?blogId=${blogId}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" }
                });

                if (res.status === 200) {
                    await deleteObject(storageRef);
                    return navigate('/view-blog-request');
                } else {
                    setErrorMessage('Internal issue for deleting blog.')
                }
            }
        } catch (error) {
            console.error("Error deleting photo:", error);
        }
    };



    //----------------------------------------- Handle UPDATE POST -------------------------------------------
    const handleUpdate = async () => {

        try {
            setLoading(true);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/blog/updateBlog/${_id}`, {
                method: "PUT",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData)
            });

            if (res.status === 200) {
                const data = await res.json();
                setLoading(false);
                setIsEditClicked(false);
                return navigate('/viewBlog', { state: data.blog });
            }
        } catch (error) {
            console.log(error);
        }
    }



    //------------------------------------- Handle Approve Blog -----------------------------
    const handleapproveBlog = async () => {
        try {
            setLoading(true);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/blog/addBlog`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData)
            });

            if (res.status === 200) {
                const data = await res.json();

                const DeleteRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/blogRequest/deleteBlog?blogId=${_id}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" }
                });

                if (DeleteRes.status === 200) {
                    setLoading(false);
                    setIsEditClicked(false);
                    return navigate('/view-blog-request');
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    //--------------------------------- upload image -----------------------------------------
    const handleImageUpload = async (e) => {
        const selectedFile = e.target.files[0];

        if (!selectedFile) {
            alert("Please select a file to upload.");
            return;
        }

        const storageRef = ref(storage, `images/${selectedFile.name}`);

        try {
            await uploadBytes(storageRef, selectedFile);
            console.log("Image uploaded successfully.");

            const downloadURL = await getDownloadURL(storageRef);

            setFormData((prevData) => ({ ...prevData, image: downloadURL }));
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };



    //--------------------------------- delete image from firebase/preview ---------------------------------
    const deleteBlogImage = async () => {
        try {
            if (!formData.image) {
                alert("No image to delete.");
                return;
            }

            const storagePath = decodeURIComponent(formData.image.split("/o/")[1].split("?")[0]);
            const storageRef = ref(storage, storagePath);

            await deleteObject(storageRef);


            setFormData((prevData) => ({ ...prevData, image: '' }));
        } catch (error) {
            console.error("Error deleting image:", error);
            alert("Failed to delete the image. Try again later.");
        }
    };



    return (
        <>
            {!authorDetails ? (
                <div className="h-screen w-screen flex justify-center items-center">
                    <Spinner size="md" />
                </div>
            ) : (
                <div className="flex flex-col max-w-[1140px] items-center p-6 bg-gray-50 mx-auto">
                    {/* Header Section */}
                    <header className="text-center mt-6">

                        <h1 className="text-3xl max-w-3xl font-bold text-gray-800 mb-4">
                            {title}
                        </h1>

                        {/* AUTHOR DETAILS */}
                        <div className="flex justify-center mb-3">
                            <div className="h-12 w-auto mx-auto flex flex-row justify-center items-center gap-3 pr-5 bg-gray-200 rounded-full">
                                <div className="w-12 h-12 rounded-full">
                                    <img src={authorDetails.profilePicture} alt="author-img" className="w-full h-full object-cover rounded-full" />
                                </div>
                                <div className="">
                                    <p className="text-xs text-gray-700">{authorDetails.name}</p>
                                    <a href={linkedinProfile} className="flex flex-row items-center">
                                        <FaLinkedin size={10} className='text-gray-500' />
                                        <p className="text-[10px] text-gray-500">LinkedIn Profile</p>
                                    </a>

                                </div>
                                <GrEdit size={20} className="text-gray-600" />
                            </div>
                        </div>


                        {/* DELETE & EDIT Buttons  (only for Admin) */}
                        {currentUser?.isAdmin &&
                            <div>
                                {!isRequest ? (
                                    <div className="mx-auto flex flex-row justify-center gap-5">
                                        <div className='w-12 h-12 bg-[#2871df] flex justify-center items-center rounded-full hover:bg-[#2757a0]'
                                            onClick={() => setIsEditClicked(true)}
                                        >
                                            <FiEdit className='text-white' size={25} />
                                        </div>
                                        <div className='w-12 h-12 bg-[#d62626] flex justify-center items-center rounded-full hover:bg-[#9c2727]'
                                            onClick={() => handleDeleteBlog(image, _id)}
                                        >
                                            <MdDelete className='text-white' size={25} />
                                        </div>
                                    </div>) : (
                                    <div className="mx-auto flex flex-row justify-center gap-5">
                                        <div className='h-12 bg-[#2871df] flex justify-center items-center rounded-full hover:bg-[#2757a0] px-4'
                                            onClick={handleapproveBlog}
                                        >
                                            <FiEdit className='text-white' size={25} />
                                            <span className="text-white text-sm">Edit & Approve</span>
                                        </div>
                                        <div className='h-12 bg-[#d62626] flex justify-center items-center rounded-full hover:bg-[#9c2727] px-4'
                                            onClick={() => handleDeleteBlog(image, _id)}
                                        >
                                            <MdDelete className='text-white' size={25} />
                                            <span className="text-white text-sm">Delete Blog</span>
                                        </div>
                                    </div>
                                )}
                            </div>}
                            
                        {errorMessage && <Alert className='text-sm' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}

                    </header>

                    {/* Category & Date */}
                    <div className="w-full max-w-3xl flex flex-row items-center justify-between gap-4">
                        <div className="inline-block text-xs font-semibold text-green-700 bg-green-100 rounded-full my-auto">
                            <p className="px-3 py-1">{category}</p>
                        </div>
                        <p className="text-xs text-gray-600">Date: <span className="text-gray-400 text-xs">{year}-{month}-{day}</span></p>
                    </div>

                    {/* Image Section */}
                    <div className="w-full max-w-3xl mt-2">
                        <img
                            src={image}
                            alt="Harvest Image"
                            className="rounded-lg w-full object-cover"
                        />
                    </div>

                    {/* Content Section */}
                    <div className="max-w-4xl mt-8 text-gray-700">
                        <div
                            className='p-3 max-w-4xl text-sm mx-auto w-full text-justify'
                            dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                    </div>
                </div>)}


            {isEditClicked && (
                <div className='h-screen w-screen bg-black bg-opacity-60 flex items-center justify-center fixed top-0 left-0 z-50'>
                    <div className="w-[750px] h-[550px] bg-gray-100 p-6 my-4 rounded-lg shadow-lg overflow-y-auto">

                        <div className='flex flex-col items-center'>
                            <RxCross1 className='text-gray-500 z-50 translate-x-[350px] -translate-y-3 hover:text-gray-800 cursor-pointer'
                                onClick={() => setIsEditClicked(false)}
                                size={18} />
                            <h2 className='text-gray-700 text-xl font-semibold'>Add A New Blog</h2>

                            {/* Title */}
                            <div className="w-full mx-3 mt-3">
                                <div className="block">
                                    <label className='font-semibold text-gray-600' htmlFor="title" >Title<span className='text-red-600 text-base'> *</span></label>
                                </div>
                                <Textarea id="title"
                                    placeholder="Write your title..."
                                    value={formData.title}
                                    required rows={2}
                                    onChange={handleChange} />
                            </div>

                            {/* Content */}
                            <div className="w-full mx-3 mt-3">
                                <div className="block">
                                    <label className='font-semibold text-gray-600' htmlFor="content">Content<span className='text-red-600 text-base'> *</span></label>
                                </div>
                                <ReactQuill theme="snow"
                                    className="h-44 mb-12"
                                    value={formData.content}
                                    required
                                    onChange={(value) => setFormData({ ...formData, content: value })}
                                />
                            </div>

                            {/* Image preview */}
                            <div className="mx-auto my-3">
                                <p className="text-sm text-gray-600 font-semibold text-center my-1">Image preview(change image)<span className='text-red-600 text-base'> *</span></p>
                                <div className="h-[200px] w-[400px] mx-auto bg-gray-200 rounded-xl relative group shadow-xl">
                                    {/* Delete Button */}
                                    {formData.image ? <div
                                        className="absolute top-2 right-2 w-8 h-8 bg-[#d62626] flex justify-center items-center rounded-full hover:bg-[#9c2727] z-10 cursor-pointer"
                                        onClick={deleteBlogImage}
                                    >
                                        <MdDelete className="text-white" size={18} />
                                    </div> : <div
                                        className="absolute top-2 right-2 w-8 h-8 bg-[#27bf27] flex justify-center items-center rounded-full hover:bg-[#278b27] z-10 cursor-pointer"
                                        onClick={() => document.getElementById('image').click()}
                                    >
                                        <RiAddLine className="text-white" size={18} />
                                    </div>
                                    }

                                    {/* Image or Placeholder */}
                                    <div className="w-full h-full flex justify-center items-center">
                                        {!formData?.image ? (
                                            <div className='flex flex-col gap-2'>
                                                <BsImageAlt className="text-gray-400 object-cover mx-auto " size={100} />
                                                <p className='text-sm font-semibold text-gray-400 text-center'>Change Image<span className='text-red-600 text-base'> *</span></p>
                                            </div>
                                        ) : (
                                            <img
                                                className="h-full w-full object-cover rounded-xl transition-opacity duration-300 group-hover:opacity-80"
                                                src={formData.image}
                                                alt="blog-img"
                                            />
                                        )}
                                    </div>
                                </div>

                            </div>


                            {/* Photo and Category */}
                            <div className='w-full flex flex-row justify-between'>
                                {/* photo */}
                                <div className='w-full my-3 flex flex-row gap-5'>
                                    <div className="hidden">
                                        <label htmlFor="image" className='block text-sm text-gray-600 font-semibold'>Upload Image</label>
                                        <input className='rounded-lg border border-gray-400 text-sm'
                                            id='image'
                                            type="file"
                                            onChange={handleImageUpload} />
                                    </div>

                                    {/* Category */}
                                    <div className="w-96 mx-auto">
                                        <label htmlFor="category" className="block text-sm font-semibold text-gray-600">Choose a Category<span className='text-red-600 text-base'> *</span></label>
                                        <select id="category"
                                            className="mt-1 block w-full border text-gray-600 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs"
                                            onChange={handleChange}
                                            value={formData.category}
                                        >
                                            <option className='text-gray-600 text-xs' value="">Select a category</option>
                                            {categories.map((category) => (
                                                <option className='text-gray-600 text-xs' value={category}>{category}</option>
                                            ))}
                                        </select>
                                    </div>

                                </div>

                            </div>


                            <div className="w-96 mx-auto mb-4">
                                <div className="block">
                                    <label className='font-semibold text-gray-600 text-sm' htmlFor="linkedinProfile" >LinkedIn profile link<span className='text-red-600 text-base'> *</span></label>
                                </div>
                                <TextInput id="linkedinProfile"
                                    className='text-xs'
                                    placeholder="Linkedin profile link"
                                    value={formData.linkedinProfile}
                                    onChange={handleChange} />
                            </div>


                            <div className="flex space-x-4">
                                <button
                                    disabled={loading}
                                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center space-x-2"
                                    onClick={handleUpdate}
                                >
                                    {!loading ? <><FaCheck /> <span>Update</span></> :
                                        <div className='flex flex-row justify-center gap-2'>
                                            <Spinner size='sm' />
                                            <p className='text-white'>Updating...</p>
                                        </div>}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
