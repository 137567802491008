import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import RobotoRegulari from '../assets/fonts/Roboto-Light.ttf';
import RobotoBlod from '../assets/fonts/Roboto-Bold.ttf';
import GreatVibesRegular from '../assets/fonts/GreatVibes-Regular.ttf';

// Register multiple fonts
Font.register({
    family: 'Roboto',
    src: RobotoRegulari
});

Font.register({
    family: 'Roboto-Bold',
    src: RobotoBlod
});

Font.register({
    family: 'Great Vibes',
    src: GreatVibesRegular,
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        height: '100%', // Ensure full height usage
    },
    leftSection: {
        width: '40%',
        height: '100%',
    },
    leftImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensures the image scales proportionally
    },
    rightSection: {
        width: '60%',
        padding: 40,
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    titleContainer: {
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainTitle: {
        color: '#0056B3',
        fontSize: 36,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 16,
        color: '#999',
        marginBottom: 20,
        textAlign: 'center',
    },
    informationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    recipientName: {
        fontSize: 40,
        color: '#333',
        fontWeight: 'bold',
        marginBottom: 15,
        fontFamily: 'Great Vibes',
    },
    hasSuccessfully: {
        justifyContent: 'flex-end',
        marginLeft: '294px',
        fontSize: 12,
        color: '#555',
        lineHeight: 1.5,
    },
    description: {
        fontSize: 12,
        color: '#555',
        lineHeight: 1.5,
    },
    highlightText: {
        fontFamily: 'Roboto-Bold',
        fontWeight: 'bold',
    },
    footer: {
        marginTop: 130,
        fontSize: 10,
        paddingTop: 5,
        borderTopWidth: 1,
        borderTopColor: '#555',
        textAlign: 'center',
        color: '#555'

    },
    signatureText: {
        fontSize: 10,
        paddingTop: 5,
        fontWeight: 'bold',
        marginTop: 5,
        borderTopWidth: 1,
        borderTopColor: '#000',
        textAlign: 'center',
    },
    logoImage: {
        width: '160px',
        marginBottom: '15px',
    },
    circleContainer: {
        marginTop: -65,
        width: 60,
        height: 60,
        borderRadius: 50,
        borderWidth: 2,
        borderColor: '#0056B3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    percentageText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#0056B3',
    },

    serialNumber: {
        marginTop: 20,
        fontSize: 8,
        fontWeight: 'normal',
        color: '#898989',
    },
    level: {
        textTransform: 'uppercase',
    }
});


const Certificate = ({name, language, level, percentage}) => {

    const showlanguage = language === 'java' ? 'Java' : language === 'dataanalyst' ? 'Data Analyst' : language === 'businessAnalyst' ? 'Business Analyst' : language === 'businessIntelligence' ? 'Business Intelligence' : language === 'financialAnalyst' ? 'Financial Analyst' : language === 'uiux' ? 'UI/UX' : language;

    const generateSerialNumber = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0'); // 3-digit random number

        return `${year}${month}${day}-${hours}${seconds}-${random}`;
    };

    const getCurrentDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    };

    const serialNumber = generateSerialNumber()
    const issuedDate = getCurrentDate();

    return (
        <Document>
            {/* Set size using the points (inches converted from pixels) */}
            <Page size={{ width: 792, height: 612 }} style={styles.page}>
                {/* Left Section with Image */}
                <View style={styles.leftSection}>
                    <Image
                        style={styles.leftImage}
                        src="./certificate-pdf-left.png"
                    />
                </View>

                {/* Right Section with Text */}
                <View style={styles.rightSection}>
                    <View style={styles.titleContainer}>
                        <Image style={styles.logoImage} src="./benda-infotech-pre.png" />
                        <Text style={styles.mainTitle}>CERTIFICATE</Text>
                        <Text style={styles.subtitle}>OF ACHIEVEMENT</Text>
                    </View>

                    {/* <View>
                        <View style={styles.circleContainer}>
                            <Text style={styles.percentageText}>96%</Text>
                        </View>
                    </View> */}

                    <View style={styles.informationSection}>
                        <Text style={styles.recipientName}>{name}</Text>
                        <Text style={styles.description}>has successfully{'\n'}completed the
                            <Text style={styles.highlightText}> {showlanguage} Test</Text> under the Horg Skill Test program. The
                            assessment covered core modules including Market Research, Sales Strategies, Client Relationship Management,
                            and Business Analysis. With a remarkable score of <Text style={styles.highlightText}>{percentage}%</Text>, Sudip Ghara has showcased exceptional skills and
                            expertise in the field of {showlanguage}.
                        </Text>
                    </View>

                    <Text style={styles.footer}>
                        Head of Skill Assessment Coordination
                    </Text>

                    <Text style={styles.serialNumber}>
                        Certificate ID: {serialNumber} | Issused On : {issuedDate} | Difficulty Level: <Text style={styles.level}>{level}</Text>
                    </Text>

                </View>
            </Page>
        </Document>
    )
};

export default Certificate;
