import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { removePreTestData, savePreTestData } from "../Redux/preTest/preTestSlice.js";
import { setSelectedSkill } from "../Redux/selectSkill/selectSkillSlice.js";
import { removeSkillTestData } from "../Redux/skillTest/skillTestSlice.js";




function Instruction() {


  const [mediaStream, setMediaStream] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [access, setAccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { preTest } = useSelector((state) => state.preTest);
  const { selectedSkill } = useSelector((state => state.selectSkill));


  const searchParams = new URLSearchParams(location.search);
  const language = searchParams.get("language");
  const level = searchParams.get("level");

  useEffect(() => {
    if (language) {
      dispatch(removeSkillTestData());
      dispatch(setSelectedSkill(language));
    }
  }, [language, dispatch]);
  
  useEffect(() => {
    if (selectedSkill && level) {
      dispatch(
        savePreTestData(
          level === 'easy' ?
            { ...easy, mediaAccess: false, fullMarks: (easy.numOfMCQ * 10) + (easy.numOfCoding * 20) } :
          level === 'medium' ?
            { ...medium, mediaAccess: false, fullMarks: (medium.numOfMCQ * 10) + (medium.numOfCoding * 30) } :
          level === 'hard' ?
            { ...hard, mediaAccess: false, fullMarks: (hard.numOfMCQ * 10) + (hard.numOfCoding * 40) } :
            { ...easy, mediaAccess: false, fullMarks: (easy.numOfMCQ * 10) + (easy.numOfCoding * 20) }
        )
      );
    }
  }, [selectedSkill, level, dispatch]);
  



  const easy = {
    language: selectedSkill,
    level: 'easy',
    numOfMCQ: selectedSkill === 'java' ? 8 : selectedSkill === 'dataanalyst' ? 18 : selectedSkill === 'businessAnalyst' ? 30 : selectedSkill === 'businessIntelligence' ? 30 : 0,
    numOfCoding: selectedSkill === 'java' ? 2 : selectedSkill === 'dataanalyst' ? 1 : selectedSkill === 'businessAnalyst' ? 0 : selectedSkill === 'businessIntelligence' ? 0 : 0,
    time: 45,
  };

  const medium = {
    language: selectedSkill,
    level: 'medium',
    numOfMCQ: selectedSkill === 'java' ? 8 : selectedSkill === 'dataanalyst' ? 20 : selectedSkill === 'businessAnalyst' ? 45 : selectedSkill === 'businessIntelligence' ? 45 : 0,
    numOfCoding: selectedSkill === 'java' ? 3 : selectedSkill === 'dataanalyst' ? 2 : selectedSkill === 'businessAnalyst' ? 0 : selectedSkill === 'businessIntelligence' ? 0 : 0,
    time: 60,
  };

  const hard = {
    language: selectedSkill,
    level: 'hard',
    numOfMCQ: selectedSkill === 'java' ? 8 : selectedSkill === 'dataanalyst' ? 25 : selectedSkill === 'businessAnalyst' ? 60 : selectedSkill === 'businessIntelligence' ? 60 : 0,
    numOfCoding: selectedSkill === 'java' ? 4 : selectedSkill === 'dataanalyst' ? 3 : selectedSkill === 'businessAnalyst' ? 0 : selectedSkill === 'businessIntelligence' ? 0 : 0,
    time: selectedSkill === 'businessAnalyst' ? 70 : selectedSkill === 'businessIntelligence' ? 70 : 90,
  };


  const handleMediaAccess = async () => {
    alert("The application needs to access your camera and microphone.");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setMediaStream(stream);
      alert("Access granted!");
      dispatch(savePreTestData({ ...preTest, mediaAccess: true }))
      setAccess(true);
    } catch (error) {
      dispatch(savePreTestData({ ...preTest, mediaAccess: false }))
      setErrorMessage("Access denied or an error occurred: " + error.message);
      alert(errorMessage);
    }
  };


  //Handle Take Test
  const handleTakeTest = (e) => {
    e.preventDefault();
    if (!access) {
      return alert('Please provide camera and microphone access.')
    }
    navigate('/skillTest');
  }




  return (
    <div className="h-full w-full bg-white-300 flex flex-col items-center">
      <h5 className="mb-2 text-2xl font-bold text-gray-800 mt-10">
        INSTRUCTIONS
      </h5>
      <div class="m-5 md:m-6  md:p-16 w-full md:w-9/12 h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">

        <div>
          <p className="my-4 underline text-center">Read the instructions carefully before hit the "Take test" button</p>
          <ol className="list-disc">
            <li className="text-gray-700 text-sm"> Arrange for stable Internet connectivity.</li>
            <li className="text-gray-700 text-sm"> Giving examination on Laptop or Desktop is highly recommended.</li>
            <li className="text-gray-700 text-sm">Make sure mobile/laptop is fully charged. Power bank for mobile
              or UPS/Inverter for laptop/desktop should be arranged for
              uninterrupted power supply.</li>
            <li className="text-gray-700 text-sm"> Once the exam starts, do not switch to any other window/tab. On
              doing so, your attempt may be considered as malpractice and your
              exam may get terminated.</li>
            <li className="text-gray-700 text-sm"> Do Not Pickup/Receive the Call during the exam if you are giving
              the exam on mobile. This also will be treated as changing the
              window. </li>
            <li className="text-gray-700 text-sm"> Only use [insert your software/platforms] to complete the exam. Using any other browsers, software, browsers, applications, artificial intelligence, and technologies is not allowed.
            </li>
            <li className="text-gray-700 text-sm">Stay on camera: remain fully visible on the webcam throughout the test.</li>
            <li className="text-gray-700 text-sm"> Take the exam independently: no one else is permitted in the test room or surrounding area.</li>
          </ol>
        </div>
        <div className="text-center mt-5">
          <h5 className="text-gray-900 font-bold underline">KEEP IN MIND</h5>
          <p className="text-sm"><span className="text-red-600 text-xl">*</span> Do not exit the full-screen mode by escape key or anything. If you do so the test will end and submit automatically.</p>
          <p className="text-sm"><span className="text-red-600 text-xl">*</span>After selecting your answer for a question, use the "Save & Next" button. This action will save your current response and move you to the next question. This step is crucial to ensure that your answer is recorded.</p>
        </div>
      </div>

      {mediaStream && (
        <div className="mt-4 mx-auto flex justify-center">
          <video
            autoPlay
            playsInline
            ref={(video) => {
              if (video) {
                video.srcObject = mediaStream;
              }
            }}
            className="w-1/2 h-auto border rounded-lg"
          />
        </div>
      )}

      {preTest && (<div className=" w-full flex flex-col items-center justify-center">
        <div className=" w-1/2 p-3 m-5 md:m-5 md:p-4 bg-white flex flex-col md:flex-row gap-3 mt-2 justify-between border-gray-200  shadow   border  rounded-lg  items-center space-x-3">
          <div className="ml-4">
            <ol className="list-disc">
              <li className="text-sm">Give access to your camera.</li>
              <li className="text-sm">Give access to your microphone.</li>
            </ol>
          </div>
          <button disabled={access} className={`text-white py-3 px-3 rounded-2xl ${access ? 'bg-green-600' : 'bg-blue-600'}`} onClick={handleMediaAccess}> {access ? (<div className=" flex flex-row gap-2"><IoMdCheckmarkCircleOutline className="h-6 w-6" color="white" /><p className="text-base text-white">Done</p></div>) : 'Allow access'}</button>

        </div>
        <button onClick={handleTakeTest} type="button" className=" mt-10 mb-3 px-6 py-3.5 text-base font-medium  text-white bg-green-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-lg  me-2  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">TAKE TEST</button>
      </div>)}
    </div>

  );
}
export default Instruction;