import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaClipboardList, FaComments } from 'react-icons/fa';
import { BiPhoneCall, BiEnvelope } from 'react-icons/bi';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { Alert, Spinner } from 'flowbite-react';
import { MdError } from 'react-icons/md';

const TalkToExpertForm = () => {

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isQuerySent, setIsQuerySent] = useState(false);
    const [emptyFieldMessage, setEmptyFieldMessage] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        query: '',
        contactMethod: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setEmptyFieldMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Save formData for further use
        console.log('Form Data:', formData);
        alert('Form submitted!');
    };


    //----------------- handle send mail to TEAM -------------------
    const handleSendMail = async (e) => {
        e.preventDefault();
        try {
            if (formData.name === '' || formData.email === '' || formData.phone === '' ||
                formData.subject === '' || formData.query === '' || formData.contactMethod === '') {
                return setEmptyFieldMessage('* Please fill all the fields.');
            }
            setLoading(true);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/nodemailer/talk-to-expert`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            const data = await res.json();
            if (res.status === 200) {
                setErrorMessage('');
                setIsQuerySent(true);
                setLoading(false);
                setSuccessMessage('Query has been successfully sent. Expert team will connect with you as soon as possible.')
            } else {
                setLoading(false);
                setSuccessMessage('');
                setErrorMessage('Internal server error. Please try again later.')
            }

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="max-w-[1140px] mx-20 my-4 bg-white shadow-lg rounded-lg py-10 px-14">
            <h2 className="text-2xl font-bold mb-6 text-center">Fill form to hear from our Expert</h2>
            {isQuerySent && successMessage !== '' ? (
                <Alert className='text-[14px]' color="success" icon={IoCheckmarkDoneCircleOutline}>{successMessage}</Alert>) :
                errorMessage !== '' ? (
                    <Alert className='text-[14px] text-center' color="failure" icon={MdError}>{errorMessage}</Alert>) :
                    (<form onSubmit={handleSendMail} className="space-y-4">
                        <div className='flex justify-center gap-5'>
                            {/* Name Field */}
                            <div className="w-full flex items-center border rounded p-2">
                                <FaUser className="text-gray-500 mr-2" />
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Name"
                                    className="w-full outline-none rounded-md text-sm"
                                />
                            </div>

                            {/* Email Field */}
                            <div className="w-full flex items-center border rounded p-2">
                                <FaEnvelope className="text-gray-500 mr-2" />
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    className="w-full outline-none rounded-md text-sm"
                                />
                            </div>
                        </div>

                        <div className='flex justify-center gap-5'>
                            {/* Phone Number Field */}
                            <div className="w-full flex items-center border rounded p-2">
                                <FaPhone className="text-gray-500 mr-2" />
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder="Phone Number"
                                    className="w-full outline-none rounded-md text-sm"
                                />
                            </div>

                            {/* Query Subject Field */}
                            <div className="w-full flex items-center border rounded p-2">
                                <FaClipboardList className="text-gray-500 mr-2" />
                                <input
                                    type="text"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleInputChange}
                                    placeholder="Query Subject"
                                    className="w-full outline-none rounded-md text-sm"
                                />
                            </div>
                        </div>

                        {/* Explain Query */}
                        <div className="border rounded p-2">
                            <textarea
                                name="query"
                                rows="4"
                                value={formData.query}
                                onChange={handleInputChange}
                                placeholder="Explain your query"
                                className="w-full outline-none resize-none rounded-md text-sm"
                            ></textarea>
                        </div>

                        {/* Contact Method */}
                        <div className="flex justify-start gap-10">
                            <p className="text-sm tect-gray-700 font-semibold">Want to hear from us via:</p>
                            <div className='flex gap-5'>
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id="call"
                                        name="contactMethod"
                                        value="call"
                                        checked={formData.contactMethod === 'call'}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="call" className="ml-2 flex items-center text-sm">
                                        <BiPhoneCall className="text-gray-500 mr-1 " /> Call
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id="email"
                                        name="contactMethod"
                                        value="email"
                                        checked={formData.contactMethod === 'email'}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="email" className="ml-2 flex items-center text-sm">
                                        <BiEnvelope className="text-gray-500 mr-1 " /> Email
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className='flex flex-col items-end'>
                            <p className='text-[14px] text-red-600 py-2'>{emptyFieldMessage}</p>
                            <button
                                disabled={loading}
                                type="submit"
                                className="w-40 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                            >
                                {loading ? (
                                    <span className="flex gap-2 justify-center items-center">
                                        <Spinner size='sm' />
                                        <span className="text-sm">Submitting</span>
                                    </span>
                                ) : 'Submit'}
                            </button>
                        </div>

                    </form>)}
        </div>
    );
};

export default TalkToExpertForm;