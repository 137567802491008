
import { useSelector, useDispatch } from 'react-redux';
import { savePreTestData, removePreTestData } from '../Redux/preTest/preTestSlice.js';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import '../styles/buttons.css'
import { FaArrowRightLong, FaRegShareFromSquare } from "react-icons/fa6";
import Lottie from "lottie-react";
import java from "../assets/java.json"
import dataAnalyst from '../assets/dataAnalyst.json';
import businessAnalyst from '../assets/businessAnalyst.json';
import businessIntelligence from '../assets/businessIntelligence.json';
import defaultAnimation from '../assets/defaultAnimation.json';
import financialAnalyst from '../assets/financialAnalyst.json';
import uiux from '../assets/uiux.json';
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { useEffect, useState } from 'react';
import UiuCustomUiuxDesignsxTest from './CustomUiuxDesigns.jsx';



// Reusable CopyLinkButton component
const CopyLinkButton = ({ language, level }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const hardcodedLink = `https://h-or-g.com/testInstruction?language=${language}&level=${level}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(hardcodedLink)
            .then(() => {
                setIsClicked(true);
                setTimeout(() => setIsClicked(false), 2000); // Reset message after 2 seconds
            })
            .catch(err => {
                console.error("Failed to copy text: ", err);
            });
    };


    useEffect(() => {
        // Register GSAP ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);

        // Select all elements with the 'about-us' class
        const popUpwardsSections = document.querySelectorAll(".pop-upwards");
        const leftToRightSections = document.querySelectorAll(".left-to-right");
        const rightToLeftSections = document.querySelectorAll(".right-to-left");
        const rotateClockwiseSections = document.querySelectorAll(".rotate-clockwaise");
        const flip360Sections = document.querySelectorAll(".flip-360");

        popUpwardsSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, y: 40 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.3,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 88%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        leftToRightSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, x: -40 },
                {
                    opacity: 1,
                    x: 0,
                    duration: 1.2,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 90%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        rightToLeftSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, x: 40 },
                {
                    opacity: 1,
                    x: 0,
                    duration: 1.2,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 88%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        rotateClockwiseSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, rotation: 0 },
                {
                    opacity: 1,
                    rotation: 90,
                    duration: 1.5,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 80%",
                        toggleActions: "play none none none",
                        once: true,
                    },
                    delay: index * 0.2,
                }
            );
        });

        flip360Sections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, rotationY: 0 },
                {
                    opacity: 1,
                    rotationY: 360, // Flip 360 degrees
                    duration: 2.5,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 90%",
                        toggleActions: "play none none none",
                        once: false,
                    },
                    delay: index * 0.8,
                    repeat: -1,
                    repeatDelay: 3
                }
            );
        });
    }, []);

    return (
        <div className="relative">
            <FaRegShareFromSquare
                className="mt-3 text-gray-300 cursor-pointer"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={copyToClipboard}
            />

            {/* Tooltip message */}
            <div
                className={`absolute left-0 mt-1 text-xs text-white bg-gray-700 px-2 py-1 rounded-lg 
                ${isHovered || isClicked ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1'} 
                transition-opacity duration-300 ease-in-out`}
            >
                {isClicked ? 'Link copied' : 'Copy test link'}
            </div>
        </div>
    );
};


export default function TestOptions() {

    const navigate = useNavigate();
    const { currentUser } = useSelector((state) => state.user);
    const { selectedSkill } = useSelector((state => state.selectSkill))

    const [isHoveredEasy, setIsHoveredEasy] = useState(false);
    const [isHoveredMedium, setIsHoveredMedium] = useState(false);
    const [isHoveredHard, setIsHoveredHard] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const language = searchParams.get("language");

    const easy = {
        language: selectedSkill,
        level: 'easy',
        numOfMCQ: selectedSkill === 'java' ? 8 : selectedSkill === 'dataanalyst' ? 18 : selectedSkill === 'businessAnalyst' ? 30 : selectedSkill === 'businessIntelligence' ? 30 : selectedSkill === 'financialAnalyst' ? 30 : selectedSkill === 'uiux' ? 30 : 0,
        numOfCoding: selectedSkill === 'java' ? 2 : selectedSkill === 'dataanalyst' ? 1 : selectedSkill === 'businessAnalyst' ? 0 : selectedSkill === 'businessIntelligence' ? 0 : selectedSkill === 'uiux' ? 0 : 0,
        time: 45,
    };

    const medium = {
        language: selectedSkill,
        level: 'medium',
        numOfMCQ: selectedSkill === 'java' ? 8 : selectedSkill === 'dataanalyst' ? 20 : selectedSkill === 'businessAnalyst' ? 45 : selectedSkill === 'businessIntelligence' ? 45 : selectedSkill === 'financialAnalyst' ? 45 : selectedSkill === 'uiux' ? 45 : 0,
        numOfCoding: selectedSkill === 'java' ? 3 : selectedSkill === 'dataanalyst' ? 2 : selectedSkill === 'businessAnalyst' ? 0 : selectedSkill === 'businessIntelligence' ? 0 : selectedSkill === 'uiux' ? 0 : 0,
        time: 60,
    };

    const hard = {
        language: selectedSkill,
        level: 'hard',
        numOfMCQ: selectedSkill === 'java' ? 8 : selectedSkill === 'dataanalyst' ? 25 : selectedSkill === 'businessAnalyst' ? 60 : selectedSkill === 'businessIntelligence' ? 60 : selectedSkill === 'financialAnalyst' ? 60 : selectedSkill === 'uiux' ? 60 : 0,
        numOfCoding: selectedSkill === 'java' ? 4 : selectedSkill === 'dataanalyst' ? 3 : selectedSkill === 'businessAnalyst' ? 0 : selectedSkill === 'businessIntelligence' ? 0 : selectedSkill === 'uiux' ? 0 : 0,
        time: selectedSkill === 'businessAnalyst' ? 70 : selectedSkill === 'businessIntelligence' ? 70 : 90,
    };





    //----------------- Handle pre-test Data--------------
    const handleTestEasy = () => {
        if (currentUser) {
            dispatch(removePreTestData());
            dispatch(savePreTestData({ ...easy, mediaAccess: false, fullMarks: (easy.numOfMCQ * 10) + (easy.numOfCoding * 20) }));
            navigate('/testInstruction')
        } else {
            navigate('/signin')
        }
    }


    const handleTestMedium = () => {
        if (currentUser) {
            dispatch(removePreTestData());
            dispatch(savePreTestData({ ...medium, mediaAccess: false, fullMarks: (medium.numOfMCQ * 10) + (medium.numOfCoding * 30) }));
            navigate('/testInstruction')
        } else {
            navigate('/signin')
        }
    }


    const handleTestHard = () => {
        if (currentUser) {
            dispatch(removePreTestData());
            dispatch(savePreTestData({ ...hard, mediaAccess: false, fullMarks: (hard.numOfMCQ * 10) + (hard.numOfCoding * 40) }));
            navigate('/testInstruction')
        } else {
            navigate('/signin')
        }
    }


    useEffect(() => {
        if (selectedSkill === '') {
            navigate('/selectSkill');
        } else {
            dispatch(removePreTestData());
        }
    }, [])


    return (
        <div className='mt-8 flex flex-col justify-center items-center'>
            <div className='mb-6 flex justify-center items-center gap-5'>
                <h2 className='text-2xl font-semibold text-zinc-800 left-to-right '>Predefined test sets for
                    <span className='font-bold'>{` ${selectedSkill === 'java' ? 'Java' : selectedSkill === 'dataanalyst' ? 'Data-Analyst' : selectedSkill === 'businessAnalyst' ? 'Business-Analyst' : selectedSkill === 'businessIntelligence' ? 'Business-Intelligence' : selectedSkill === 'uiux' ? 'UI/UX' : selectedSkill}`}
                    </span>
                </h2>
                <BsReverseLayoutTextWindowReverse className='right-to-left' size={26} />

            </div>
            <div
                className="h-72 mx-3 flex flex-col md:flex-row justify-center items-center flex-wrap gap-3"
            >
                <div
                    className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 left-to-right"
                    onMouseEnter={() => setIsHoveredEasy(true)}
                    onMouseLeave={() => setIsHoveredEasy(false)}
                >
                    <div className="p-4 bg-gradient-to-r from-green-800 to-green-300">
                        <div className="flex flex-row items-center gap-5">
                            <div className="">
                                <h2 className="text-xl font-semibold  text-white">Easy</h2>
                                <p className="text-gray-100 text-xs">
                                    Get a brief description of test.
                                </p>
                                <CopyLinkButton language={selectedSkill} level='easy' />
                            </div>
                            <div className='border rounded-md shadow-md ml-2 my-2'>
                                <Lottie className="h-20 " animationData={selectedSkill === 'java'
                                    ? java
                                    : selectedSkill === 'dataanalyst'
                                        ? dataAnalyst
                                        :
                                        selectedSkill === 'businessAnalyst' ? businessAnalyst :
                                            selectedSkill === 'businessIntelligence' ? businessIntelligence :
                                                selectedSkill === 'financialAnalyst' ? financialAnalyst :
                                                    selectedSkill === 'uiux' ? uiux : defaultAnimation}></Lottie>
                            </div>
                        </div>


                        <div
                            className={`mt-4 text-white overflow-hidden transition-all duration-300 ${isHoveredEasy ? "border-t max-h-96 opacity-100" : "max-h-0 opacity-0"
                                }`}
                        >
                            <p className="text-xs mt-3 mb-3 font-normal dark:text-gray-400">
                                <b>Prerequisite: </b><span>{selectedSkill === 'dataanalyst' ? 'Data Analyst' : selectedSkill === 'businessAnalyst' ? 'Business Analyst' : selectedSkill === 'businessIntelligence' ? 'Business Intelligence' : selectedSkill === 'financialAnalyst' ? 'Financial Analyst' : selectedSkill}</span>
                            </p>
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Time: </b>{easy.time} minutes.
                            </p>
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Full marks: </b>{(easy.numOfMCQ * 10) + (easy.numOfCoding * 20)}
                            </p>
                            {easy.numOfCoding !== 0 && <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Coding problems: </b>{easy.numOfCoding}
                            </p>}
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>MCQ: </b>{easy.numOfMCQ}
                            </p>
                            <div className="flex flex-row justify-center items-center">
                                <button onClick={handleTestEasy} class="button button--telesto font-thin">
                                    <span>
                                        <span className="font-normal not-italic">Take test</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 pop-upwards"
                    onMouseEnter={() => setIsHoveredMedium(true)}
                    onMouseLeave={() => setIsHoveredMedium(false)}
                >
                    <div className="p-4 bg-gradient-to-r from-blue-800 to-cyan-300">
                        <div className="flex flex-row items-center gap-5">
                            <div className="">
                                <h2 className="text-xl font-semibold  text-white">Medium</h2>
                                <p className="text-gray-100 text-xs">
                                    Get a brief description of test.
                                </p>
                                <CopyLinkButton language={selectedSkill} level='medium' />
                            </div>
                            <div className='border rounded-md shadow-md ml-2 my-2'>
                                <Lottie className="h-20 " animationData={selectedSkill === 'java'
                                    ? java
                                    : selectedSkill === 'dataanalyst'
                                        ? dataAnalyst
                                        :
                                        selectedSkill === 'businessAnalyst' ? businessAnalyst :
                                            selectedSkill === 'businessIntelligence' ? businessIntelligence :
                                                selectedSkill === 'financialAnalyst' ? financialAnalyst :
                                                    selectedSkill === 'uiux' ? uiux : defaultAnimation}></Lottie>
                            </div>
                        </div>
                        <div
                            className={`mt-4 text-white overflow-hidden transition-all duration-300 ${isHoveredMedium ? "border-t max-h-96 opacity-100" : "max-h-0 opacity-0"
                                }`}
                        >
                            <p className="text-xs mt-3 mb-3 font-normal dark:text-gray-400">
                                <b>Prerequisite: </b><span>{`Intermidiate in ${selectedSkill === 'dataanalyst' ? 'Data Analyst' : selectedSkill === 'businessAnalyst' ? 'Business Analyst' : selectedSkill === 'businessIntelligence' ? 'Business Intelligence' : selectedSkill === 'financialAnalyst' ? 'Financial Analyst' : selectedSkill}`}</span>
                            </p>
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Time: </b>{medium.time} minutes.
                            </p>
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Full marks: </b>{(medium.numOfMCQ * 10) + (medium.numOfCoding * 30)}
                            </p>
                            {medium.numOfCoding !== 0 && <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Coding problems: </b>{medium.numOfCoding}
                            </p>}
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>MCQ: </b>{medium.numOfMCQ}
                            </p>
                            <div className="flex flex-row justify-center items-center">
                                <button onClick={handleTestMedium} class="button button--telesto">
                                    <span>
                                        <span className="font-normal not-italic">Take test</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 right-to-left"
                    onMouseEnter={() => setIsHoveredHard(true)}
                    onMouseLeave={() => setIsHoveredHard(false)}
                >
                    <div className="p-4 bg-gradient-to-r from-red-950 to-red-600 ">
                        <div className="flex flex-row items-center gap-5">
                            <div className="">
                                <h2 className="text-xl font-semibold  text-white">Hard</h2>
                                <p className="text-gray-100 text-xs">
                                    Get a brief description of test.
                                </p>
                                <CopyLinkButton language={selectedSkill} level='hard' />
                            </div>
                            <div className='border rounded-md shadow-md ml-2 my-2'>
                                <Lottie className="h-20 " animationData={selectedSkill === 'java'
                                    ? java
                                    : selectedSkill === 'dataanalyst'
                                        ? dataAnalyst
                                        :
                                        selectedSkill === 'businessAnalyst' ? businessAnalyst :
                                            selectedSkill === 'businessIntelligence' ? businessIntelligence :
                                                selectedSkill === 'financialAnalyst' ? financialAnalyst :
                                                    selectedSkill === 'uiux' ? uiux : defaultAnimation}></Lottie>
                            </div>
                        </div>
                        <div
                            className={`mt-4 text-white overflow-hidden transition-all duration-300 ${isHoveredHard ? "border-t max-h-96 opacity-100" : "max-h-0 opacity-0"
                                }`}
                        >
                            <p className="text-xs mt-3 mb-3 font-normal dark:text-gray-400">
                                <b>Prerequisite: </b>{`Proficient in ${selectedSkill === 'dataanalyst' ? 'Data Analyst' : selectedSkill === 'businessAnalyst' ? 'Business Analyst' : selectedSkill === 'businessIntelligence' ? 'Business Intelligence' : selectedSkill === 'financialAnalyst' ? 'Financial Analyst' : selectedSkill}`}
                            </p>
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Time: </b>{hard.time} minutes.
                            </p>
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Full marks: </b>{(hard.numOfMCQ * 10) + (hard.numOfCoding * 40)}
                            </p>
                            {hard.numOfCoding !== 0 && <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>Coding problems: </b>{hard.numOfCoding}
                            </p>}
                            <p className="text-xs mb-3 font-normal dark:text-gray-400">
                                <b>MCQ: </b>{hard.numOfMCQ}
                            </p>
                            <div className="flex flex-row justify-center items-center">
                                <button onClick={handleTestHard} class="button button--telesto">
                                    <span>
                                        <span className="font-normal not-italic">Take test</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Ui/Ux Design section */}
            {/* {selectedSkill === 'uiux' &&
                <UiuCustomUiuxDesignsxTest />
            } */}

            <Link to='/testInstruction' className='mt-4 md:mt-14'>
                <button class="button button--greip">
                    <span><span>Instructions</span></span>
                </button></Link>
        </div>
    );
}
