import React, { useState, useEffect } from 'react';
import { HiInformationCircle, HiUser } from "react-icons/hi";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { RxCross1 } from "react-icons/rx";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Modal, Spinner } from "flowbite-react";

import { useNavbarVisibility } from '../../../components/NavbarVisibilityContext.jsx'
import { useFooterVisibility } from '../../../components/FooterVisibilityContext.jsx';
import GoogleSignUp from '../../../components/googleAuth/SignupGoogleAuth.jsx';
import { removeErrorAndLoad, signInStart, signInSuccess } from '../../../Redux/user/userSlice.js';
import { updateAllViewedProfiles, updateRecruiterInfo } from '../../../Redux/recruiterSlice.js';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet-async';


export default function Signup() {


    const { error, currentUser } = useSelector((state) => state.user);

    const majorSkills = [
        { value: 'react', name: 'React' },
        { value: 'nodejs', name: 'Node.js' },
        { value: 'mongodb', name: 'MongoDB' },
        { value: 'expressjs', name: 'Express.js' },
        { value: 'data-analyst', name: 'Data Analyst' },
        { value: 'java-developer', name: 'Java Developer' },
        { value: 'business-analyst', name: 'Business Analyst' },
        { value: 'business-intelligence', name: 'Business Intelligence' },
        { value: 'cpp-developer', name: 'C++ Developer' },
        { value: 'python-developer', name: 'Python Developer' },
        { value: 'full-stack-developer', name: 'Full Stack Developer' },
        { value: 'front-end-developer', name: 'Front-End Developer' },
        { value: 'back-end-developer', name: 'Back-End Developer' },
        { value: 'software-engineer', name: 'Software Engineer' },
        { value: 'devops-engineer', name: 'DevOps Engineer' },
        { value: 'machine-learning-engineer', name: 'Machine Learning Engineer' },
        { value: 'data-scientist', name: 'Data Scientist' },
        { value: 'cybersecurity-specialist', name: 'Cybersecurity Specialist' },
        { value: 'cloud-engineer', name: 'Cloud Engineer' },
        { value: 'database-administrator', name: 'Database Administrator' },
        { value: 'mobile-developer', name: 'Mobile Developer' },
        { value: 'qa-engineer', name: 'Quality Assurance (QA) Engineer' },
        { value: 'technical-support-engineer', name: 'Technical Support Engineer' },
        { value: 'web-developer', name: 'Web Developer' },
        { value: 'android-developer', name: 'Android Developer' },
        { value: 'ios-developer', name: 'iOS Developer' },
        { value: 'game-developer', name: 'Game Developer' },
        { value: 'ui-ux-designer', name: 'UI/UX Designer' },
        { value: 'blockchain-developer', name: 'Blockchain Developer' },
        { value: 'network-administrator', name: 'Network Administrator' },
        { value: 'systems-analyst', name: 'Systems Analyst' },
        { value: 'it-consultant', name: 'IT Consultant' },
        { value: 'product-manager', name: 'Product Manager' },
        { value: 'project-manager', name: 'Project Manager' },
        { value: 'big-data-engineer', name: 'Big Data Engineer' },
        { value: 'ai-researcher', name: 'AI Researcher' },
        { value: 'embedded-systems-engineer', name: 'Embedded Systems Engineer' },
        { value: 'robotics-engineer', name: 'Robotics Engineer' },
        { value: 'security-analyst', name: 'Security Analyst' },
        { value: 'software-architect', name: 'Software Architect' },
        { value: 'site-reliability-engineer', name: 'Site Reliability Engineer' },
        { value: 'technical-writer', name: 'Technical Writer' },
        { value: 'salesforce-developer', name: 'Salesforce Developer' },
        { value: 'sap-consultant', name: 'SAP Consultant' },
        { value: 'scrum-master', name: 'Scrum Master' },
        { value: 'ux-researcher', name: 'UX Researcher' },
        { value: 'animation-specialist', name: 'Animation Specialist' },
        { value: 'video-editor', name: 'Video Editor' },
        { value: 'network-security-engineer', name: 'Network Security Engineer' },
        { value: 'others', name: 'Others' }
    ];


    const [isVisible, setIsVisible] = useState(false);
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');
    const [userMajorSkill, setUserMajorSkill] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showMajorSkillDropdown, setShowMajorSkillDropdown] = useState(true);
    const [isGoogleClicked, setIsGoogleClicked] = useState(false);


    const { setIsNavbarVisible } = useNavbarVisibility();
    const { setIsFooterVisible } = useFooterVisibility();

    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();



    //saving form data
    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.id]: e.target.value });
    }

    // go to thee prvious page
    const handleGoBack = () => {
        setIsFooterVisible(true);
        setIsNavbarVisible(true);
        navigate('/');
    }


    //toggle eye button
    const handleEyePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    }
    const handleEyeConfirmPassword = (e) => {
        e.preventDefault();
        setShowConfirmPassword(!showConfirmPassword);
    }

    // Radio button handler
    const handleRoleChange = (e) => {
        const isJobSeeker = e.target.value === 'false';
        setFormData({ ...formData, isRecruiter: e.target.value });
        setShowMajorSkillDropdown(isJobSeeker);
    };

    // Dropdown handler
    const handleMajorSkillChange = (e) => {
        setFormData({ ...formData, majorSKill: e.target.value });
    }

    useEffect(() => {
        // Check if cookie consent is already set
        setIsVisible(true);
        setIsFooterVisible(false);
        setIsNavbarVisible(false);
    }, []);


    //---------------------------------Handle Google Signup--------------------------------
    const handleGoogleSignup = (e) => {
        e.preventDefault();
        dispatch(removeErrorAndLoad());
        setIsGoogleClicked(true);
    }

    // handle close modal
    const handleCloseModal = () => {
        dispatch(removeErrorAndLoad());
        setIsGoogleClicked(false);
    }


    //     ----------------------------  signup handler function --------------------------
    const handleSignUp = async (e) => {
        e.preventDefault();

        const validateEmail = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\.outlook\.com|\.com))$/;

        // Validate name
        if (!formData.name) {
            return setUserName('Please enter your full name.');
        } else {
            setUserName('');
        }

        // Validate email
        if (!formData.email) {
            return setUserEmail('Please enter your email address.');
        } else {
            setUserEmail('');
        }

        // Validate email structure
        if (!validateEmail.test(formData.email)) {
            return setUserEmail('Please enter a valid email address.');
        } else {
            setUserEmail('');
        }

        // Validate password
        if (!formData.password) {
            return setUserPassword('Set password.');
        } else {
            setUserPassword('');
        }

        // Validate confirm password
        if (!formData.confirmPassword) {
            return setUserConfirmPassword('Re-enter your password.');
        } else {
            setUserConfirmPassword('');
        }

        // validate major skill
        if (showMajorSkillDropdown && !formData.majorSKill) {
            return setUserMajorSkill('Please choose a skill')
        } else {
            setUserMajorSkill('');
        }

        // Match confirm password and password
        if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
            return setUserConfirmPassword('Password does not match.');
        } else {
            setUserConfirmPassword('');
        }

        // All validations passed, proceed with the signup process
        try {
            setLoading(true);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(formData)
            });
            const data = await res.json();

            if (data.success === false) {
                setLoading(false);
                return setErrorMessage(data.message);
            }

            if (res.ok) {
                dispatch(signInSuccess(data.user));
                cookies.set('access_token', data.token);

                // Redirect according to the User Type
                
                //Admin
                if (data.user.isAdmin) {

                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    navigate('/dashboard?tab=dash');

                    // Recruiter
                } else if(data.user.isRecruiter){

                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    navigate('/recruiter-form');

                    // Candidate
                }else{

                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    navigate('/userForm')
                }


                // try{
                //     const mailRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/nodemailer/welcomeMail`, {
                //         method: 'POST',
                //         headers: { 'Content-Type': 'application/json' },
                //         body: JSON.stringify({email: formData.email, name: formData.name})
                //     });
                // }catch(err){
                //     console.log(err);
                // }

            }
        } catch (err) {
            console.log(err);
        }
        return
    };


    if (!isVisible) {
        return null; // Don't render the banner if the consent is already given
    }

    return (
        <>
        <Helmet>
                <title>Sign Up for free skill test | Start Your Journey Today</title>
                <meta
                    name="description"
                    content="Join us today and take your first step toward success with our free skill test. Sign up now to discover your potential and unlock new opportunities—quick, easy, and completely free!
"
                />
            </Helmet>
        <div className='h-auto w-screen  flex items-center justify-center  top-0 left-0 '>
            <img className='object-cover fixed inset-0 w-full h-full opacity-75' src="./images/auth-bg.webp" alt="horg-logo" />
            <div className="w-[450px] my-6 h-auto bg-white text-white p-6 flex flex-col items-center justify-center rounded-lg shadow-lg z-50">
                <RxCross1 className='text-gray-300 relative z-50 ml-[394px] -mt-3 hover:text-gray-400 cursor-pointer'
                    size={18}
                    onClick={handleGoBack} />
                <div className='flex flex-col items-center gap-3'>
                    <img className='w-32' src="./images/logo-blue.png" alt="horg logo" />
                    <div className='flex flex-col py-1 mb-3'>
                        <div className='flex flex-row justify-center gap-1 -mt-2'>
                            <HiUser className='h-6 w-6' color='gray' />
                            <h1 className='text-gray-500 text-[17px] text-center'>SignUp</h1>
                        </div>
                    </div>
                </div>
                <form className="flex justify-center max-w-lg flex-col gap-4 w-full" onSubmit={handleSignUp}>
                    <div className='w-full'>
                        <div class="relative">
                            <input type="text" id="name" aria-describedby="name_help" class="block px-2 pb-2 pt-2 w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none dark:text-white dark:blue-green-500  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={handleChange} />
                            <label for="name" class="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Name</label>
                        </div>
                        {userName && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userName}</p>}
                    </div>
                    <div className='w-full'>
                        <div class="relative">
                            <input type="text" id="email" aria-describedby="email_help" class="block px-2 pb-2 pt-2 w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none dark:text-white dark:blue-green-500 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={handleChange} />
                            <label for="email" class="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                        </div>
                        {userEmail && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userEmail}</p>}
                    </div>
                    <div>
                        <div className='flex flex-row-reverse'>
                            <div class="relative w-full">
                                <input type={`${showPassword ? 'text' : 'password'}`} id="password" aria-describedby="password_help" class="block px-2 pb-2 pt-2 w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none dark:text-white dark:blue-green-500 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={handleChange} />
                                <label for="password" class="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
                            </div>
                            {showPassword ? (
                                <div className='absolute p-3 cursor-pointer'><FaRegEye onClick={handleEyePassword} color='grey' /></div>
                            ) : (
                                <div className='absolute p-3 cursor-pointer'><FaRegEyeSlash onClick={handleEyePassword} color='grey' /></div>
                            )}
                        </div>
                        {userPassword && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userPassword}</p>}
                    </div>
                    <div>
                        <div className='flex flex-row-reverse'>
                            <div class="relative w-full">
                                <input type={`${showConfirmPassword ? 'text' : 'password'}`} id="confirmPassword" aria-describedby="password_help" class="block px-2 pb-2 pt-2 w-full text-sm text-gray-800 bg-transparent rounded-lg border-1 border-blue-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " onChange={handleChange} />
                                <label for="confirmPassword" class="absolute text-sm text-blue-800 dark:text-blue-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Confirm password</label>
                            </div>
                            {showConfirmPassword ? (
                                <div className='absolute p-3 cursor-pointer'><FaRegEye onClick={handleEyeConfirmPassword} color='grey' /></div>
                            ) : (
                                <div className='absolute p-3 cursor-pointer'><FaRegEyeSlash onClick={handleEyeConfirmPassword} color='grey' /></div>
                            )}
                        </div>
                        {userConfirmPassword && <p class="mt-1 text-xs text-red-700 dark:text-red-700">{userConfirmPassword}</p>}
                    </div>

                    {/* Radio Buttons for Role Selection */}
                    <div >
                        <h3 className='font-semibold text-gray-700 text-sm my-4'>Choose how you want to proceed:<span className='text-red-600 text-base'> *</span></h3>
                        <div className="flex gap-4 mb-2">
                            <label className='text-gray-700 text-xs'>
                                <input type="radio" name="role" className='text-blue-700' value={false} onChange={handleRoleChange} defaultChecked /> Proceed as job seeker
                            </label>
                            <label className='text-gray-700 text-xs'>
                                <input type="radio" name="role" className='text-blue-700 px-2' value={true} onChange={handleRoleChange} /> Proceed as recruiter
                            </label>
                        </div>
                        {/* Dropdown for Major Skill */}
                        {showMajorSkillDropdown && (
                            <div className="w-full ease-in-out transition-all my-2">
                                <label htmlFor="majorSkill" className="block text-sm font-semibold text-gray-600">Choose a Major Skill<span className='text-red-600 text-base'> *</span></label>
                                <p className='text-gray-500 text-[10px]'>
                                    It will help us to sort and showcase your profile to the recruiters.
                                </p>
                                <select id="majorSkill"
                                    className="mt-1 block w-full border text-gray-600 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs"
                                    onChange={handleMajorSkillChange}
                                    value={formData.majorSkill}>
                                    <option className='text-gray-600 text-xs' value="">Select a skill</option>
                                    {majorSkills.map((skill) => (
                                        <option className='text-gray-600 text-xs' value={skill.value}>{skill.name}</option>
                                    ))}
                                </select>

                            </div>
                        )}
                        {userMajorSkill && <p class="mt-1 text-xs text-red-700">{userMajorSkill}</p>}
                    </div>
                    {errorMessage && <Alert className='text-sm' color="failure" icon={HiInformationCircle}>{errorMessage}</Alert>}
                    <Button className='mt-2'
                        type="submit"
                        gradientDuoTone='cyanToBlue'
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <Spinner size='sm' />
                            </>
                        ) : 'SignUp'}</Button>
                    <button onClick={handleGoogleSignup} className=" mt-2 bg-gray-600 text-white text-xs flex justify-center items-center gap-2 py-2 rounded-md hover:bg-gray-500 hover:text-gray-100" type="button">
                        <img className="w-5 h-5 mr-2 " src="./images/google.png.png" alt="google img" />
                        Sign-Up with Google
                    </button>
                    <p className='-mb-4 py-4 text-gray-600 text-xs -mt-5'>Already have an account? <b><Link to='/signin' className='text-blue-500 text-xs'>SignIn</Link></b></p>
                </form>
            </div>

            <Modal show={isGoogleClicked}
                onClose={handleCloseModal}
                popup
                size='md'
            >

                <div className='text-center'>
                    <Modal.Header />
                    <Modal.Body>
                        {error ? (
                            <Alert className='text-[12px]' color="failure" icon={HiInformationCircle}>{error}</Alert>
                        ) : (
                            <div className='flex flex-col item-start'>
                                <h3 className='font-semibold text-gray-700 text-sm my-4 text-start'>Choose how you want to proceed:<span className='text-red-600 text-base'> *</span></h3>
                                <div className="flex gap-4 mb-2">
                                    <label className='text-gray-700 text-xs'>
                                        <input type="radio" name="role" className='text-blue-700' value={false} onChange={handleRoleChange} defaultChecked /> Proceed as job seeker
                                    </label>
                                    <label className='text-gray-700 text-xs'>
                                        <input type="radio" name="role" className='text-blue-700 px-2' value={true} onChange={handleRoleChange} /> Proceed as recruiter
                                    </label>
                                </div>
                                {/* Dropdown for Major Skill */}
                                {showMajorSkillDropdown && (
                                    <div className="w-full ease-in-out transition-all my-2">
                                        <label htmlFor="majorSkill" className="block text-sm font-semibold text-gray-600 text-start">Choose a Major Skill<span className='text-red-600 text-base'> *</span></label>
                                        <p className='text-gray-500 text-[10px] text-start'>
                                            It will help us to sort and showcase your profile to the recruiters.
                                        </p>
                                        <select id="majorSkill"
                                            className="mt-1 block w-full border text-gray-600 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs"
                                            onChange={handleMajorSkillChange}
                                            value={formData.majorSkill}>
                                            <option className='text-gray-600 text-xs' value="">Select a skill</option>
                                            {majorSkills.map((skill) => (
                                                <option className='text-gray-600 text-xs' value={skill.value}>{skill.name}</option>
                                            ))}
                                        </select>

                                    </div>
                                )}
                                {userMajorSkill && <p class="mt-1 text-xs text-red-700">{userMajorSkill}</p>}
                            </div>)}
                    </Modal.Body>
                    <Modal.Footer className="justify-center">
                        <GoogleSignUp formData={formData} />
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
        </>
    );
}