
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "benda-v1.firebaseapp.com",
  projectId: "benda-v1",
  storageBucket: "benda-v1.firebasestorage.app",
  messagingSenderId: "106405191582",
  appId: "1:106405191582:web:1ce6f538aed8a9be5f808f",
  measurementId: "G-EJX35BDLV4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, app };