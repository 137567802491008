
import { app } from "../../firebase.js";
import { useDispatch } from "react-redux";
import { signInStart, signInSuccess, signInFailure } from "../../Redux/user/userSlice.js";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { updateAllViewedProfiles, updateRecruiterInfo } from "../../Redux/recruiterSlice.js";
import Cookies from "universal-cookie";
import { useNavbarVisibility } from "../NavbarVisibilityContext.jsx";
import { useFooterVisibility } from "../FooterVisibilityContext.jsx";



export default function GoogleAuth() {

    const auth = getAuth(app);

    const { setIsNavbarVisible } = useNavbarVisibility();
    const { setIsFooterVisible } = useFooterVisibility();

    const cookies = new Cookies();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();

    const handleGoogleClick = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        try {
            dispatch(signInStart());
            const resultfromGoogle = await signInWithPopup(auth, provider);
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/googleSignin`, {
                method: "POST",
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: resultfromGoogle.user.displayName,
                    email: resultfromGoogle.user.email,
                    googlePhotoUrl: resultfromGoogle.user.photoURL,
                }),
            })

            const data = await res.json();

            if (res.status === 404) {
                return dispatch(signInFailure("No user found with this email! Sign up now."));
            }

            if (res.ok) {

                dispatch(signInSuccess(data.user));
                cookies.set('access_token', data.token);

                // ------------------------------ if user is Admin -------------------------------
                if (data.user.isAdmin) {

                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);
                    navigate('/dashboard?tab=dash');

                    //--------------------------- if user is Recruiter -------------------------------
                } else if (data.user.isRecruiter && data.user.recruiterInfoId !== '') {
                    try {
                        const recruiterRes = await fetch(`${process.env.REACT_APP_BACKEND}/api/recruiter/getRecruiter/${data.user.recruiterInfoId}`, {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                            credentials: 'include',

                        });

                        const recruiterInfo = await recruiterRes.json();

                        if (recruiterRes.ok) {
                            dispatch(updateRecruiterInfo(recruiterInfo.user));
                            dispatch(updateAllViewedProfiles(recruiterInfo.viewedProfiles));
                            setIsFooterVisible(true);
                            setIsNavbarVisible(true);
                            navigate('/dashboard?tab=recruiterDash')
                        }
                    } catch (error) {
                        dispatch(signInFailure(error.message))
                        console.log(error)
                    }

                    // -------------------- if user is Candidate --------------------
                } else {
                    setIsFooterVisible(true);
                    setIsNavbarVisible(true);

                    // Extract query string from location.search
                    const queryString = location.search;
                    const redirectPath = location.state?.from?.pathname || '/';
                    const finalRedirect = `${redirectPath}${queryString}`;
                    // Redirect with the query string
                    navigate(finalRedirect, { replace: true });
                }
            }

        } catch (error) {
            dispatch(signInFailure(error.message));
            console.log(error);
        }
    }

    return (
        <button onClick={handleGoogleClick} className=" mt-2 bg-gray-600 text-white text-xs flex justify-center items-center gap-2 py-2 rounded-md hover:bg-gray-500 hover:text-gray-100" type="button">
            <img className="w-5 h-5 mr-2 " src="./images/google.png.png" alt="google img" />
            Continue with Google
        </button>
    )
}