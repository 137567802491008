import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Rect, Path, Circle } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import RobotoRegulari from '../assets/fonts/Roboto-Light.ttf';

// Register the font
Font.register({
    family: 'Roboto',
    src: RobotoRegulari,
});
const styles = StyleSheet.create({
    page: {
        padding: 20,
        backgroundColor: '#F4F8FB',
        fontSize: 12,
        color: '#01579B',
        width: '100%',
        height: '100%',
        fontFamily: 'Roboto', // Apply the Roboto font
    },
    header: {
        backgroundColor: '#01579B',
        color: '#E1F5FE',
        padding: 15,
        textAlign: 'center',
        borderBottom: '5px solid #0288D1',
        marginBottom: 20,
    },
    header1Icon: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    header1: {
        fontSize: 20,
        color: '#000000',
        padding: 15,
        textAlign: 'center',
        marginBottom: 20,
    },
    logo: {
        width: 120,
        marginBottom: 10,
        alignSelf: 'center',
    },
    candidateInfo: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#E3F2FD',
        borderRadius: 10,
    },
    infoText: {
        fontSize: 14,
        color: '#01579B',
    },
    resultStatus: {
        marginTop: 20,
        fontSize: 22,
        color: '#FF5252',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    resultStatusPass: {
        marginTop: 20,
        fontSize: 22,
        color: '#008000',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    percentageText: {
        marginTop: 10,
        fontSize: 20,
        color: '#01579B',
        textAlign: 'center',
    },
    section: {
        marginTop: 20,
        padding: 15,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    breakdownSection: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#E3F2FD',
        padding: 15,
        borderRadius: 10,
    },
    breakdownRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    breakdownText: {
        fontSize: 12,
        color: '#000000',
        width: '20%',
    },
    breakdownBarContainer: {
        width: '70%',
        backgroundColor: '#ECEFF1',
        height: 12,
        borderRadius: 6,
        overflow: 'hidden',
    },
    breakdownBar: {
        height: 12,
        borderRadius: 6,
        backgroundColor: '#0288D1',
    },
    // Adjusted for marks and pie chart placement
    scoreAndChartContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 30,
        padding: 20,
        backgroundColor: '#FFF3E0',
        borderRadius: 10,
    },
    scoreSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '60%', // Ensure the marks section occupies a larger area
    },
    scoreText: {
        fontSize: 18,
        color: '#0288D1',
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    pieChart: {
        width: 120,
        height: 120,
        borderRadius: '50%',
    },
    questionSection: {
        marginTop: 30,
        padding: 20,
        backgroundColor: '#FFF3E0',
        borderRadius: 10,
        borderTop: '2px solid #0288D1',
    },
    footerText: {
        textAlign: 'center',
        marginTop: 20,
        fontSize: 10,
        color: '#0288D1',
    }, pieChart: {
        position: 'relative', // Important for text overlay
        width: 100,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center', // Centers the text horizontally
    },
    textContainer: {
        position: 'absolute', // Allows positioning over the circle
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center', // Centers text vertically
        alignItems: 'center', // Centers text horizontally
    },
    pieChartText: {
        fontSize: 18,
        color: '#000',
        fontWeight: 'bold',
    },
});
// Utility function to handle safe division
const safeDivision = (numerator, denominator) => {
    return denominator > 0 ? numerator / denominator : 0;
};
// Component to display PDF content
const PDF_viewer = ({ name, email, exam, level, no_of_mcq, no_of_coding, totalMarksobtained, totalMarks, time_taken, total_time, mcqAnswerCount, codingAnswerCount }) => {
    const subjects = [
        { name: 'Coding', no: no_of_coding, correct: codingAnswerCount },
        { name: 'Multiple Choice Questions', no: no_of_mcq, correct: mcqAnswerCount },
    ];
    const formattedDate = new Date().toLocaleDateString();
    const percentage = totalMarks > 0 ? ((totalMarksobtained / totalMarks) * 100).toFixed(2) : 0;
    const pieChartValue = totalMarks > 0 ? Math.min((totalMarksobtained / totalMarks) * 282, 282) : 0;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Header */}
                <View style={styles.header}>
                    <Image style={styles.logo} src="benda-infotech.png" />
                    <Text style={styles.reportTitle}>Evaluation Report</Text>
                    <Text>{formattedDate}</Text>
                </View>
                <View>
                    <Text style={styles.header1}>Skill Assessement Test for {exam === 'dataanalyst' ? 'Data Analyst' : exam === 'java' ? "Java" : exam === 'businessAnalyst' ? 'Business Analyst' : exam === 'businessIntelligence' ? 'Business-Intelligence' :
                        exam === 'financialAnalyst' ? 'Financial Analyst' : (
                            <>
                                <Text>{`${exam}`}</Text>
                            </>
                        )} ({level})</Text>
                </View>
                {/* Candidate Information */}
                <View style={styles.candidateInfo}>
                    <Text style={styles.infoText}>Name: {name}</Text>
                    <Text style={styles.infoText}>Email: {email}</Text>
                    {/* <Text style={styles.infoText}>Exam: {exam}</Text>
                    <Text style={styles.infoText}>Level: {level}</Text> */}
                </View>
                {/* Result Status */}
                <Text style={
                    level === 'easy' &&
                        (exam === 'dataanalyst' || exam === 'java' && (no_of_coding > 0 && codingAnswerCount < 1)) && totalMarksobtained /
                        totalMarks < 0.8 ? styles.resultStatus :
                        level === 'medium' &&
                            (exam === 'dataanalyst' || exam === 'java' && (no_of_coding > 0 && codingAnswerCount < 1)) && totalMarksobtained /
                            totalMarks < 0.7 ? styles.resultStatus :
                            level === 'easy' &&
                                (exam === 'dataanalyst' || exam === 'java' && (no_of_coding > 0 && codingAnswerCount < 1)) && totalMarksobtained /
                                totalMarks < 0.8 ? styles.resultStatus : styles.resultStatusPass}>

                    {
                        level === 'easy' &&
                            (exam === 'dataanalyst' || exam === 'java' && (no_of_coding > 0 && codingAnswerCount < 1)) && totalMarksobtained /
                            totalMarks < 0.8 ? 'FAIL' :
                            level === 'medium' &&
                                (exam === 'dataanalyst' || exam === 'java' && (no_of_coding > 0 && codingAnswerCount < 1)) && totalMarksobtained /
                                totalMarks < 0.7 ? 'FAIL' :
                                level === 'easy' &&
                                    (exam === 'dataanalyst' || exam === 'java' && (no_of_coding > 0 && codingAnswerCount < 1)) && totalMarksobtained /
                                    totalMarks < 0.6 ? 'FAIL' : 'PASS'}

                </Text>
                <Text style={styles.percentageText}>Total: {percentage}%</Text>
                {/* Breakdown Section */}
                <View style={styles.breakdownSection}>
                    {subjects.map((subject, index) => (
                        <View key={index} style={styles.breakdownRow}>
                            {subject.no > 0 && <Text style={styles.breakdownText}>{subject.name}</Text>}
                            {subject.no > 0 && <View style={styles.breakdownBarContainer}>
                                {subject.no > 0 && <View
                                    style={[
                                        styles.breakdownBar,
                                        { width: `${safeDivision(subject.correct, subject.no) * 100}%`, backgroundColor: index % 2 === 0 ? '#1E40AF' : '#0288D1' },
                                    ]}
                                />}
                            </View>}
                            {subject.no > 0 && <Text style={styles.breakdownText}>{subject.correct}/{subject.no}</Text>}
                        </View>
                    ))}
                </View>
                {/* Pie Chart and Score */}
                <View style={styles.scoreAndChartContainer}>
                    {/* Scores Section */}
                    <View style={styles.scoreSection}>
                        <Text style={styles.scoreText}>Obtained marks: {totalMarksobtained}</Text>
                        <Text style={styles.scoreText}>Total marks: {totalMarks}</Text>
                    </View>
                    {/* Pie Chart Section */}
                    {/* <View style={styles.pieChart}>
                        <Svg >
                            <Circle cx="50" cy="50" r="45" stroke="#D1D5DB" strokeWidth="10" fill="none" />
                            <Circle
                                cx="50"
                                cy="50"
                                r="45"
                                stroke={totalMarksobtained / totalMarks > 0.5 ? '#1E40AF' : '#D32F2F'}
                                strokeWidth="10"
                                strokeDasharray={`${pieChartValue}, 282`}
                                fill="none"
                                strokeLinecap="round"
                            />
                        </Svg>
                        
                    </View> */}
                    <View style={styles.pieChart}>
                        <Svg height="100" width="100">
                            {/* Background Circle */}
                            <Circle cx="50" cy="50" r="45" stroke="#D1D5DB" strokeWidth="10" fill="none" />

                            {/* Foreground Circle for dynamic progress */}
                            <Circle
                                cx="50"
                                cy="50"
                                r="45"
                                stroke={totalMarksobtained / totalMarks > 0.5 ? '#1E40AF' : '#D32F2F'}
                                strokeWidth="10"
                                strokeDasharray={`${pieChartValue}, 282`}
                                fill="none"
                                strokeLinecap="round"
                            />
                        </Svg>

                        {/* Text in the middle */}
                        <View style={styles.textContainer}>
                            <Text style={styles.pieChartText}>
                                {`${Math.round((totalMarksobtained / totalMarks) * 100)}%`}
                            </Text>
                        </View>
                    </View>
                </View>
                {/* Time Section */}
                <View style={styles.breakdownSection}>
                    <View style={styles.breakdownRow}>
                        <Text style={styles.breakdownText}>Total Time:</Text>
                        <Text style={styles.breakdownText}>{total_time} mins</Text>
                    </View>
                    <View style={styles.breakdownRow}>
                        <Text style={styles.breakdownText}>Time Taken:</Text>
                        <Text style={styles.breakdownText}>{time_taken} mins</Text>
                    </View>
                </View>
                {/* Footer */}
                <Text style={styles.footerText}>© BendaInfotech.com all rights reserved.</Text>
            </Page>
        </Document>
    );
};
export default PDF_viewer;