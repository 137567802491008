import { useSelector, useDispatch } from 'react-redux';
import { setSelectedSkill } from '../Redux/selectSkill/selectSkillSlice.js';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Lottie from "lottie-react";
import { Button } from "flowbite-react";
import java from "../assets/java.json"
import python from "../assets/python.json"
import c from "../assets/c.json"
import dataAnalyst from '../assets/dataAnalyst.json';
import businessAnalyst from '../assets/businessAnalyst.json';
import businessIntelligence from '../assets/businessIntelligence.json'
import financialAnalyst from '../assets/financialAnalyst.json';
import uiux from '../assets/uiux.json';
import { FaArrowRightLong } from 'react-icons/fa6';
import { Helmet } from 'react-helmet-async';


export default function SelectSkill() {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.user);

    const handleInstruction = () => {
        navigate('/skillTestInstruction')
    }


    const handleJavaDev = () => {
        const targetUrl = `/testOptions?language=java`;
        dispatch(setSelectedSkill('java'))
        if (currentUser) {
            navigate('/testOptions?language=java')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleDataAnalyst = () => {
        const targetUrl = `/testOptions?language=dataanalyst`;
        dispatch(setSelectedSkill('dataanalyst'))
        if (currentUser) {
            navigate('/testOptions?language=dataanalyst')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleBusinessAnalyst = () => {
        const targetUrl = `/testOptions?language=businessAnalyst`;
        dispatch(setSelectedSkill('businessAnalyst'))
        if (currentUser) {
            navigate('/testOptions?language=businessAnalyst')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleBusinessIntelligence = () => {
        const targetUrl = `/testOptions?language=businessIntelligence`;
        dispatch(setSelectedSkill('businessIntelligence'))
        if (currentUser) {
            navigate('/testOptions?language=businessIntelligence')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleFinancialAnalyst = () => {
        const targetUrl = `/testOptions?language=financialAnalyst`;
        dispatch(setSelectedSkill('financialAnalyst'))
        if (currentUser) {
            navigate('/testOptions?language=financialAnalyst')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    const handleUiUx = () => {
        const targetUrl = `/testOptions?language=uiux`;
        dispatch(setSelectedSkill('uiux'))
        if (currentUser) {
            navigate('/testOptions?language=uiux')
        } else {
            navigate(`/signin?redirect=${targetUrl}`);
        }
    }

    //------------------ Handle Coming Soon --------------------
    const handleComingSoon = () => {
        if (currentUser) {
            navigate('/comingSoon')
        } else {
            navigate('/signin')
        }
    }
    return (
        <>
        <Helmet>
                <title>Skill assessment tools for hire or get hired</title>
                <meta
                  name="description"
                  content="Explore powerful skill test tools to simplify hiring and help candidates showcase their expertise. Ideal for recruiters seeking top talent and job seekers aiming to stand out. Make hiring smarter and faster!"
                />
              </Helmet>
              
        <div className='w-screen flex justify-center'>

            <div className='flex justify-center flex-wrap items-center gap-10 mt-6 width-container'>
                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={java} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">JAVA Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Basic JAVA</p>
                    <button
                        onClick={handleJavaDev}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={dataAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Data Analyst</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Python, Excel, SQL</p>
                    <button
                        onClick={handleDataAnalyst}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow  hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={businessAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Business Analyst</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Tableau, Advance Excel, SQL, Statistical Analytics</p>
                    <button
                        onClick={handleBusinessAnalyst}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={businessIntelligence} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Business Intelligence</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Power BI, Tableau, Advance Excel, SQL, Statistical Analytics</p>
                    <button
                        onClick={handleBusinessIntelligence}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={financialAnalyst} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Financial Analyst</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Excel, Power BI, SQL, Business Studies, Quant, Fundamental and Technical Analysis, Stock Market, Economics</p>
                    <button
                        onClick={handleFinancialAnalyst}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={uiux} ></Lottie> </div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">UI/UX</h5>
                    </h1>
                    <p className="mb-3 text-[10px] font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:  </b>Ui/Ux, HTML</p>
                    <button
                        onClick={handleUiUx}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>


                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={python}></Lottie></div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">PYTHON Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:</b>Python</p>
                    <button
                        onClick={handleComingSoon}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>

                <div className="w-full mx-2 md:w-72 p-6 bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl">
                    <div className='border rounded-md shadow-md mb-4'><Lottie className='h-48' animationData={c}></Lottie></div>
                    <h1>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">C++ Developer</h5>
                    </h1>
                    <p className="mb-3 text-xs font-normal text-gray-700 dark:text-gray-400"><b>Prerequisite:</b>C++</p>
                    <button
                        onClick={handleComingSoon}
                        className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
                    >
                        Take the test
                        <FaArrowRightLong
                            size={20}
                            className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                        />
                    </button>
                </div>
            </div>
        </div>
        </>
    )
}



