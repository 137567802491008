

import { Sidebar } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { HiUser, HiArrowSmRight, HiOutlineDocumentText, HiOutlineUsers, HiAnnotation, HiChartPie } from 'react-icons/hi';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaRankingStar, FaUsersRays } from 'react-icons/fa6';
import { RiUserSearchFill } from 'react-icons/ri';
import { SiTestcafe } from "react-icons/si";
import { FaUsersCog } from "react-icons/fa";
import { MdPersonSearch } from "react-icons/md";
import { BiSolidCustomize } from "react-icons/bi";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSignOut } from '../Redux/user/userSlice.js';
import { removePreTestData } from '../Redux/preTest/preTestSlice.js';
import { removeSkillTestData } from '../Redux/skillTest/skillTestSlice.js';
import { removeSelectedSkill } from '../Redux/selectSkill/selectSkillSlice.js';
import { removeRecruiterInfo } from '../Redux/recruiterSlice.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default function DashSidebar() {

    const location = useLocation();
    const [tab, setTab] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector((state) => state.user);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');
        if (tabFromUrl) {
            setTab(tabFromUrl);
        }

    }, [location.search]);



    //-------------- GSAP Animation -----------------------
    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);
        const aboutUsSections = document.querySelectorAll(".gsap-animation");

        aboutUsSections.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, x: -30 }, // Initial state
                {
                    opacity: 1,
                    x: 0,
                    duration: 1.3,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: section, // Animation starts when the top of the section is 95% into the viewport
                        toggleActions: "play none none none", // Play animation on scroll
                        once: true, // Run animation only once
                    },
                    delay: index * 0.2, // Add a delay of 300ms for each subsequent section
                }
            );
        });
    }, []);

    // -----------------------------Handle SignOut-------------------
    const handleSignOut = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/signout`, {
                method: 'POST',
                credentials: 'include'
            });

            const data = await res.json();

            if (!res.ok) {
                console.log(data.message);
            } else {
                dispatch(userSignOut());
                dispatch(removePreTestData());
                dispatch(removeSkillTestData());
                dispatch(removeSelectedSkill());
                dispatch(removeRecruiterInfo());
                navigate('/signin')
            }

        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <Sidebar className='w-full md:w-56 border-r'>
            <Sidebar.Items>
                <Sidebar.ItemGroup className='flex flex-col gap-1'>
                    {currentUser.isAdmin || currentUser.isRecruiter && (
                        <Link className="gsap-animation"
                            to={currentUser.isAdmin ? '/dashboard?tab=dash' : '/dashboard?tab=recruiterDash'}>
                            <Sidebar.Item active={tab === 'dash' || !tab}
                                icon={HiChartPie}
                                as='div'

                            >
                                Dashboard
                            </Sidebar.Item>
                        </Link>
                    )
                    }
                    <Link className="gsap-animation" to={currentUser.isRecruiter ? '/dashboard?tab=recruiterProfile' : '/dashboard?tab=profile'}>
                        <Sidebar.Item active={tab === currentUser.isRecruiter ? 'profile' : 'recruiterProfile'}
                            icon={HiUser}
                            label={currentUser.isAdmin ? 'Admin' : currentUser.isRecruiter ? 'Recruiter' : 'User'}
                            labelColor='dark'
                            as='div'
                        >
                            Profile
                        </Sidebar.Item>
                    </Link>


                    {!currentUser.isAdmin && !currentUser.isRecruiter && <Link
                        className="gsap-animation"
                        to='/dashboard?tab=tests'>
                        <Sidebar.Item active={tab === 'tests'}
                            icon={SiTestcafe}
                            labelColor='dark'
                            as='div'
                        >
                            Tests
                        </Sidebar.Item>
                    </Link>}


                    {!currentUser.isAdmin && !currentUser.isRecruiter && <Link
                        className="gsap-animation"
                        to='/dashboard?tab=consult'>
                        <Sidebar.Item active={tab === 'consult'}
                            icon={FaUsersCog}
                            as='div'
                        >
                            Get Consultation
                        </Sidebar.Item>
                    </Link>}


                    {currentUser.isRecruiter && <Link
                        className="gsap-animation"
                        to='/dashboard?tab=search'>
                        <Sidebar.Item active={tab === 'search'}
                            icon={MdPersonSearch}
                            as='div'
                        >
                            Search
                        </Sidebar.Item>
                    </Link>}

                    {currentUser.isRecruiter && <Link
                        className="gsap-animation"
                        to='/dashboard?tab=testRankings'>
                        <Sidebar.Item active={tab === 'testRankings'}
                            icon={FaRankingStar}
                            as='div'
                        >
                            Test Rankings
                        </Sidebar.Item>
                    </Link>}


                    {currentUser.isRecruiter && <Link
                        className="gsap-animation"
                        to='/dashboard?tab=customTest'>
                        <Sidebar.Item active={tab === 'customTest'}
                            icon={BiSolidCustomize}
                            as='div'
                        >
                            Custom Test
                        </Sidebar.Item>
                    </Link>}


                    {currentUser.isRecruiter && <Link
                        className="gsap-animation"
                        to='/dashboard?tab=viewedCandidates'>
                        <Sidebar.Item active={tab === 'customTest'}
                            icon={FaUsersRays}
                            as='div'
                        >
                            Viewed Candidates
                        </Sidebar.Item>
                    </Link>}


                    {currentUser.isAdmin &&
                        <Link to='/dashboard?tab=users'
                            className="gsap-animation">
                            <Sidebar.Item icon={HiOutlineUsers} className='cursor-pointer' as='div'>
                                Users
                            </Sidebar.Item>
                        </Link>
                    }

                    {currentUser.isAdmin &&
                        <Link className="gsap-animation">
                            <Sidebar.Item icon={RiUserSearchFill} className='cursor-pointer' as='div'>
                                Recruiters
                            </Sidebar.Item>
                        </Link>
                    }

                    {currentUser.isAdmin &&
                        <Link className="gsap-animation">
                            <Sidebar.Item icon={FaRankingStar} className='cursor-pointer' as='div'>
                                Rankings
                            </Sidebar.Item>
                        </Link>
                    }

                    {currentUser.isAdmin &&
                        <Link className="gsap-animation">
                            <Sidebar.Item icon={FaQuestionCircle} className='cursor-pointer' as='div'>
                                Qusetions
                            </Sidebar.Item>
                        </Link>
                    }



                    <Sidebar.Item icon={HiArrowSmRight} className='cursor-pointer gsap-animation' onClick={handleSignOut}>
                        Sign Out
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
            </Sidebar.Items>
        </Sidebar>
    )
}