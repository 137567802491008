import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { Carousel } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import "../styles/buttons.css";
import { useNavbarVisibility } from "../components/NavbarVisibilityContext";
import { useFooterVisibility } from "../components/FooterVisibilityContext";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from 'react-helmet-async';

export default function HomePage() {
  const { currentUser } = useSelector((state) => state.user);

  const { setIsNavbarVisible } = useNavbarVisibility();
  const { setIsFooterVisible } = useFooterVisibility();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const navigate = useNavigate();
  const gsapContainer = useRef();

  const testimonialData = [
    {
      name: "Shivani Merchant",
      designation: "",
      company: "",
      image:
        "https://media.licdn.com/dms/image/v2/D4E03AQG_CNzV8Ilj3Q/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1727811268808?e=1733356800&v=beta&t=QaSpv2kv4KcH6hMLv_FDCMZGNEiBiJ9TayALOk_5Y2A",
      comment:
        "Working with Benda Infotech has transformed my job search. Their expert guidance and support, especially from Alina, boosted my confidence and helped me identify ideal career opportunities. A true career partner.",
    },
    {
      name: "Kwabena Duku",
      designation: "",
      company: "",
      image:
        "https://media.licdn.com/dms/image/v2/D5603AQHqCHOuOrHMuA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1728138066301?e=1735776000&v=beta&t=cA1WxS1Qe5ZjLcLrETW8OFnoj33mj-HIhKSU-qmiWcQ",
      comment:
        "Working with Benda Infotech boosted my technical skills, enhanced productivity, and provided valuable project experience. Their expert guidance, client-focused solutions, and support made the collaboration highly rewarding. Highly recommended.",
    },
    {
      name: "Anupriya Nandakumar",
      designation: "",
      company: "",
      image:
        "https://www.terrainhopperusa.com/wp-content/uploads/2019/01/avatar-woman.png",
      comment:
        "The service team, especially Alina, provided prompt and helpful support. However, communication from the training team and improvements to the study materials and testing platform are needed for a better experience.",
    },
  ];

  const sidesData = [
    {
      title: "What h-or-g.com does?",
      deatils:
        "We provide a skills assessment platform that evaluates candidates across industries, ensuring the right fit for any role. Our tools streamline hiring, making it faster and more efficient for businesses to find top talent.",
      bgImg: "",
      img: "./images/faq/faq-1.webp",
    },
    {
      title: "What types of skill assessments do you offer?",
      deatils:
        "At H-OR-G, we offer a wide range of skill assessments tailored to various roles and industries.",
      bgImg: "",
      img: "./images/faq/faq-2.webp",
    },
    {
      title: "Are the assessments recognized by employers?",
      deatils:
        "Yes, our assessments are designed to meet industry standards and are widely recognized by employers, helping you stand out during the hiring process.",
      bgImg: "",
      img: "./images/faq/faq-3.webp",
    },
    {
      title: "How soon will I know my results after the assessment?",
      deatils:
        "You will receive your results immediately after the assessment in the form of a downloadable PDF report.",
      bgImg: "",
      img: "./images/faq/faq-4.webp",
    },
    {
      title: "Do you offer interview preparation or coaching?",
      deatils:
        "Yes, h-or-g.com provides interview preparation and coaching services to help candidates improve their skills, boost confidence, and succeed in job interviews.",
      bgImg: "",
      img: "./images/faq/faq-5.webp",
    },
  ];

  useEffect(() => {
    setIsFooterVisible(true);
    setIsNavbarVisible(true);
  }, []);

  useEffect(() => {
    // Register GSAP ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);

    // Select all elements with the 'about-us' class
    const homeSections = document.querySelectorAll(".home-animation");

    homeSections.forEach((section) => {
      gsap.fromTo(
        section, // Target each section
        { opacity: 0, y: 30 }, // Initial state
        {
          opacity: 1,
          y: 0,
          duration: 1.4,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section, // Trigger animation when the section enters the viewport
            start: "top 92%", // Animation starts when the top of the section is 80% into the viewport
            toggleActions: "play none none none", // Play animation on scroll
            once: true, // Run animation only once
          },
        }
      );
    });

    return () => {
      // Clean up ScrollTrigger instances on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  //----------------------------------------- Handle Get Started ---------------------------------------
  const handleGetStarted = () => {
    if (currentUser && !currentUser.isRecruiter) {
      navigate("/selectSkill");
    } else if (currentUser && currentUser.isRecruiter) {
      navigate("/viewPlans");
    } else {
      navigate("/signup");
    }
  };

  //Handle switching to the selectSkill page
  const handleTakeTest = () => {
    navigate("/selectSkill");
  };

  return (
    <>
      <Helmet>
        <title>AI-powered online skill tests for hiring</title>
        <meta
          name="description"
          content="Discover how Horg skill tests revolutionize hiring by providing accurate, bias-free candidate assessments. Streamline your recruitment process and hire top talent faster with advanced AI-driven evaluations."
        />
      </Helmet>
      <div
        ref={gsapContainer}
        className="flex flex-col items-center w-full overflow-y-auto top-0 -mt-20"
      >
        {/* HERO section */}
        <div className="hero-section w-full  flex justify-center h-[1020px] bg-blue-800  overflow-x-hidden overflow-y-auto">
          <img
            src="./images/hero-bg.svg"
            alt="elemental-Design"
            className="absolute h-auto w-full left-0 -ml-6 opacity-15 width-container mx-auto overflow-y-hidden object-cover"
          />
          <div className="absolute md:w-[1080px] flex flex-col items-center mt-[145px] width-container ">
            {/* <p className="text-white text-center text-xs my-4 opacity-65">TalentEvaluation <b>|</b> JobReadiness <b>|</b> PlacementAssurance</p> */}
            <p className="text-white text-center font-josefinSans w-4/5 text-xl opacity-50 my-4 md:w-[650px] home-animation">
              Discover top talent faster, reduce costs, and make
              <br />
              smarter hiring decisions. Hire or Get-hired now.
            </p>
            <h1 className="font-semibold font-josefinSans text-lg md:text-[48px]  text-center md:w-[650px] text-white mt-6 home-animation">
              {" "}
              We simplify hiring with AI,
              <br />
              <br />
              helping businesses find the
              <br />
              <br />
              perfect talent.
            </h1>
            <p className="text-center"></p>

            <div className="mt-10 flex w-2/3 justify-center gap-2 md:gap-10 home-animation">
              <button
                className="s_btn uppercase font-josefinSans"
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>

            <img
              className="w-[1100px] relative rounded-lg mb-10 home-animation"
              src="./images/hero-img1.webp"
              alt="img-1"
            />
          </div>
        </div>

        {/* STEPS */}
        <div className="flex flex-row items-center gap-2 h-auto w-full width-container mt-6 md:mt-60 home-animation">
          <div className="w-72 h-48 rotate-[-25deg]">
            <div className="flex flex-col items-center gap-4">
              <p className="text-sm text-blue-800 text-center md:w-56">
                Streamline your hiring process or boost your job search -<br />
                See how it works!
              </p>
              <a href="/signup">
                <img
                  className="w-14 transform transition-transform duration-300 ease-in-out hover:scale-125"
                  src="./images/arrow.svg"
                  alt="arrow-img"
                />
              </a>
            </div>
          </div>

          <div className="w-72 h-48 ">
            <div className="flex flex-row items-center gap-4">
              <div className=" border-2 border-blue-900 rounded-full h-10 w-10 text-center flex justify-center items-center">
                <p className="text-2xl text-blue-900 p-3">1</p>
              </div>
              <h1 className="text-lg text-blue-900 font-semibold">
                Sign Up or Sign In
              </h1>
            </div>
            <div className="mt-4 ml-8">
              <p className="text-sm text-gray-600">
                Create your account by filling in basic information or log in
                quickly using Google.
              </p>
            </div>
          </div>

          <div className="w-72 h-48 mx-6">
            <div className="flex flex-row items-center gap-4">
              <div className=" border-2 border-blue-900 rounded-full h-10 w-10 text-center flex justify-center items-center">
                <p className="text-2xl text-blue-900 p-3">2</p>
              </div>
              <h1 className="text-lg text-blue-900 font-semibold">
                Complete Your Profile
              </h1>
            </div>
            <div className="mt-4 ml-8">
              <p className="text-sm text-gray-600">
                Tell us a bit more about yourself. If you’re a recruiter, we’ll
                complete an additional verification for secure access.
              </p>
            </div>
          </div>

          <div className="w-72 h-48 ">
            <div className="flex flex-row items-center gap-4">
              <div className=" border-2 border-blue-900 rounded-full h-10 w-10 text-center flex justify-center items-center">
                <p className="text-2xl text-blue-900 p-3">3</p>
              </div>
              <h1 className="text-lg text-blue-900 font-semibold">
                Start Using Dashboard
              </h1>
            </div>
            <div className="mt-4 ml-8">
              <p className="text-sm text-gray-600">
                Access all features right away! Whether sending skill tests or
                finding job opportunities, start exploring now.
              </p>
            </div>
          </div>

          {/* <div className="mb-10">
                                    <button class="relative text-sky-600 font-semibold px-4 py-2 overflow-hidden group">
                                        Start Journey
                                        <HiArrowNarrowRight className="inline-block" size={20} />
                                        <span class="absolute bottom-0 left-0 w-full h-0.5 bg-sky-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out origin-left"></span>
                                    </button>
                                </div> */}
        </div>

        {/* TestiMonials */}
        <div class="relative w-full h-auto max-w-[1040px] my-14 home-animation">
          <div class="w-full h-64 bg-blue-50 absolute inset-0 rounded-lg -translate-x-6 translate-y-6 shadow-md"></div>
          <div class="absolute inset-0 w-full h-64 bg-blue-200 rounded-lg -translate-x-3 translate-y-3 shadow-md"></div>
          <div class="relative w-full h-64 bg-blue-700 text-white p-8 rounded-lg shadow-lg">
            <Carousel
              pauseOnHover
              indicators={false}
              leftControl={
                <IoIosArrowDropleft className="h-8 w-8 text-gray-300 opacity-25" />
              }
              rightControl={
                <IoIosArrowDropright className="h-8 w-8 text-gray-300 opacity-25" />
              }
            >
              {testimonialData.map((testimonial, index) => (
                <div
                  key={index}
                  className="h-full w-full px-8 flex justify-center items-center"
                >
                  <div className="mt-6 mx-40 mb-6 h-full w-full flex flex-row items-center justify-center">
                    <img
                      className="w-[40px] h-[40px]  mr-4 mb-[74px] rounded-full  border-2 border-gray-200 shadow-lg shadow-gray-800 object-cover"
                      src={testimonial.image}
                      alt="..."
                    />

                    <div className="my-5 inline-block text-left">
                      <p className="font-semibold text-base mt-3  text-white text-left">
                        {testimonial.name}
                        <br />
                      </p>
                      {(testimonial.designation || testimonial.company) && (
                        <p className="text-gray-200 text-[10px] text-center border-t">
                          {testimonial.designation && testimonial.designation}{" "}
                          {testimonial.company && testimonial.company}
                        </p>
                      )}
                      <span className="inline-block align-middle">
                        <ImQuotesLeft
                          size={30}
                          className="mr-1 text-gray-400"
                        />
                      </span>

                      <p className="inline text-gray-200 text-xs text-left align-middle">
                        {testimonial.comment}
                      </p>
                      <span className="inline-block align-middle">
                        <ImQuotesRight
                          size={30}
                          className="ml-1 text-gray-400"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        {/* CARD SECTION */}
        <div className="w-full h-auto flex flex-col items-center justify-center mb-14 mt-14">
          {/* CARD 1 */}
          <div className="w-full h-auto max-w-[1140px] p-4 flex flex-row items-center home-animation">
            {/* photo section */}
            <div>
              <div className="w-[500px] h-[400px] absolute bg-sky-100 rounded-lg shadow-lg"></div>
              <div className="w-[460px] h-[350px] absolute bg-sky-200 translate-x-12 translate-y-6 rounded-lg shadow-lg"></div>
              <div
                className="w-[460px] h-[350px] relative bg-sky-800 translate-x-10 translate-y-4 rounded-lg shadow-lg"
                style={{
                  backgroundImage: "url(./images/card-1.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="ml-32">
              <p className="text-lg text-gray-500">AI & Human Expertise</p>
              <h2 className="my-2 text-gray-700 font-semibold text-2xl">
                Streamline Your Hiring Process
              </h2>
              <p className="text-gray-800 text-sm my-3">
                Find top candidates faster with AI-powered resume matching.
                Assess technical skills before interviews with customized skill
                tests. Access a curated selection of exclusive resumes,
                pre-screened by our recruiting experts.
              </p>
              <button
                onClick={handleTakeTest}
                className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
              >
                Explore more
                <FaArrowRightLong
                  size={20}
                  className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>

          {/* CARD 2 */}
          <div className="h-auto max-w-[1140px] p-4 flex flex-row-reverse items-center mt-12 home-animation">
            {/* photo section */}
            <div className="ml-24 mr-32 mb-52">
              <div className="w-[500px] h-[400px] absolute bg-teal-100 rounded-lg shadow-md"></div>
              <div className="w-[380px] h-[300px] absolute bg-teal-200 translate-x-12 translate-y-6 rounded-lg shadow-md"></div>
              <div
                className="w-[380px] h-[300px] absolute bg-sky-800 translate-x-10 translate-y-4 rounded-lg shadow-md"
                style={{
                  backgroundImage: "url(./images/card-2.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="w-[350px] h-[120px] bg-sky-300 translate-x-28 translate-y-56 rounded-lg shadow-md"
                style={{
                  backgroundImage: "url(./images/card-2-top.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="h-[500px] flex flex-col justify-center items-end">
              <p className="text-lg text-gray-500">Security Features</p>
              <h2 className="my-2 text-gray-700 font-semibold text-2xl">
                Protect Skill Test Integrity
              </h2>
              <p className="text-gray-800 text-sm my-3 text-end">
                Ensure a secure and fair skill test environment with advanced
                anti-cheating measures. Utilize browser lockdown and AI-driven
                proctoring to prevent unauthorized access, protecting the
                integrity of your skill tests and delivering accurate,
                trustworthy results.
              </p>
              <button
                onClick={handleTakeTest}
                className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
              >
                Explore more
                <FaArrowRightLong
                  size={20}
                  className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>

          {/* CARD 3 */}
          <div className="flex flex-row h-auto w-full max-w-[1140px] items-center mt-16 home-animation">
            {/* photo */}
            <div className="h-[520px] w-[420px]">
              <div className="w-[500px] h-[400px] absolute bg-teal-100 rounded-lg shadow-md"></div>
              <div
                className="w-[480px] h-[380px] bg-teal-300 translate-x-8 translate-y-8 rounded-lg shadow-md"
                style={{
                  backgroundImage: "url(./images/card-3.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="h-auto flex flex-col items-start ml-44 -mt-14">
              <p className="text-lg text-gray-500">Reporting & Analytics</p>
              <h2 className="my-2 text-gray-700 font-semibold text-2xl">
                Optimize Your Hiring Decisions
              </h2>
              <p className="text-gray-800 text-sm my-3 text-start">
                Gain deep insights into your hiring process with customizable
                dashboards and detailed analytics. Track key performance
                indicators like pass rates, time-to-hire, and candidate quality,
                empowering you to make data-driven decisions and continuously
                enhance recruitment outcomes.
              </p>
              <button
                onClick={handleTakeTest}
                className="group font-josefinSans text-white text-sm px-4 mr-5 py-2 bg-blue-700 rounded-sm relative overflow-hidden hover:bg-blue-800"
              >
                Explore more
                <FaArrowRightLong
                  size={20}
                  className="text-white mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>

          {/* CARD 4 */}
          <div className="flex flex-row-reverse h-auto w-full max-w-[1140px] items-center mt-8 home-animation">
            {/* photo */}
            <div className="h-[600px] w-[420px] mr-16">
              <div
                className="w-[460px] h-[520px] absolute bg-sky-100 rounded-lg shadow-lg"
                style={{
                  backgroundImage: "url(./images/faq/faq-bg.webp)",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="w-[400px] h-[420px] bg-sky-300 -translate-x-40 translate-y-12 rounded-lg shadow-lg"
                style={{
                  backgroundImage: `url(${sidesData[selectedIndex].img})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            {/* Text Section */}
            <div className="h-auto flex flex-col items-start mr-56">
              {sidesData.map((item, index) => (
                <div
                  key={index}
                  className={`p-4 cursor-pointer ${
                    selectedIndex === index ? "bg-gray-200 rounded-md" : ""
                  }`}
                  onClick={() => setSelectedIndex(index)}
                >
                  <h3 className="text-gray-700 font-semibold border-gray-700">
                    {item.title}
                  </h3>
                  {selectedIndex === index && (
                    <p className="text-gray-600 text-[11px] mt-2">
                      {item.deatils}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Card Section */}
        {/* <div className="width-container lg:w-[1150px] my-16 ">
            
                                <div className="w-full flex flex-col md:flex-row items-center md:justify-center gap-2 md:gap-0">
                                    <div className="relative overflow-hidden mx-2 lg:min-w-[650px] md:h-64 bg-orange-200 rounded-lg ">
                                        <img className="absolute ml-44 lg:ml-72 -mt-14 " src="./images/card1bg.png" alt="bg-1" />
                                        <div className="flex flex-col gap-6 w-2/3 p-4">
                                            <h2 className="text-gray-700 text-xl font-semibold">Talent Evaluation</h2>
                                            <p className="text-sm text-gray-600">
                                                Identify top talent quickly with accurate skill assessments. Our platform evaluates candidates across roles and industries, ensuring the right fit to drive success in your organization.
                                            </p>
                                            <button className="text-gray-700 w-1/2 border border-gray-700 rounded-full py-2 hover:text-gray-200 hover:bg-gray-700 hover:border-gray-200">
                                                See more
                                            </button>
                                        </div>
                                    </div>
            
                                    <div className="relative overflow-hidden mx-2 lg:w-full md:h-64 bg-gray-200 rounded-lg">
                                        <img className="absolute ml-56 mt-20 w-44" src="./images/card2bg2.png" alt="bg-1" />
                                        <div className="flex flex-col gap-6 w-2/3 p-4">
                                            <h2 className="text-gray-700 text-xl font-semibold">Job Readiness</h2>
                                            <p className="text-sm text-gray-600">Prepare candidates for success with comprehensive assessments that measure real-world skills.
                                                Evaluate and ensure candidates are fully prepared to excel in any role.
                                            </p>
                                            <button className="text-gray-700 w-1/2 border border-gray-700 rounded-full py-2 hover:text-gray-200 hover:bg-gray-700 hover:border-gray-200">
                                                See more
                                            </button>
                                        </div>
                                    </div>
                                </div>
            
                                <div className="w-full flex flex-col md:flex-row md:justify-center items-center mt-4 gap-2">
                                    <div className="relative overflow-hidden mx-2 lg:min-w-[500px] md:h-64 bg-gray-200 rounded-lg">
                                        <img className="absolute ml-60 mt-16 lg:mt-10 w-32 lg:w-52" src="./images/card3bg.png" alt="bg-1" />
                                        <div className="flex flex-col gap-6 w-2/3 p-4">
                                            <h2 className="text-gray-700 text-xl font-semibold">Placement Assurance</h2>
                                            <p className="text-sm text-gray-600">
                                                Guarantee the right fit with verified skills for successful job placements.
                                            </p>
                                            <button className="text-gray-700 w-1/2 border border-gray-700 rounded-full py-2 hover:text-gray-200 hover:bg-gray-700 hover:border-gray-200">
                                                See more
                                            </button>
                                        </div>
                                    </div>
            
            
                                    <div className="relative overflow-hidden mx-2 w-full md:w-full md:h-64 bg-gray-200 rounded-lg">
                                        <img className="relative w-full h-full object-cover" src="./images/card4bg.jpeg" alt="bg-1" />
            
                                        <button className="absolute bottom-4 left-4 text-white text-sm font-semibold bg-customYellow px-5 py-2 rounded-full hover:bg-yellow-400">
                                            View top candidates
                                        </button>
                                    </div>
                                </div>
            
                            </div> */}

        {/* Expertise with proper direction */}
        {/* <div className="w-full width-container relative overflow-hidden mx-2 my-4 md:h-auto bg-yellow-50 rounded-lg  shadow-lg flex 
                        flex-col-reverse md:flex-row">
            
                                <div className="flex flex-col justify-center items-center gap-6 min-w-1/2 max-w-[650px] p-4">
                                    <h2 className="text-gray-700 text-2xl text-center font-semibold">Expertise with proper direction</h2>
                                    <p className="text-sm text-gray-600 text-center">
                                        Having the right skills is essential, but it's guidance that transforms potential into success. With the proper direction, individuals can sharpen their expertise, align with industry demands, and reach their full potential. Our platform not only assesses skills but also provides insights and feedback to guide candidates towards growth, ensuring they are not just capable but well-prepared for the challenges ahead.
                                    </p>
                                    <button className="text-gray-700 w-1/2 border border-gray-700 rounded-full py-2 hover:text-gray-200 hover:bg-gray-700 hover:border-gray-200">
                                        See more
                                    </button>
                                </div>
                                <div className="w-full">
                                    <Lottie className="" animationData={cardAnimation1}></Lottie>
                                </div>
                            </div> */}

        {/* FAQ Questions */}
        {/* <div className="w-full width-container relative overflow-hidden mx-2 my-6 md:h-auto rounded-lg flex flex-col md:flex-row justify-center">
            
                                <div className="flex flex-col justify-center items-center gap-6 min-w-1/2  p-4">
                                    <h2 className="text-gray-700 text-3xl font-semibold">Frequently asked</h2>
                                    <img className="w-72" src="./images/questions.png" alt="question-img" />
                                    <img className="h-72" src="./images/faqs.svg" alt="faq-image" />
            
                                </div>
                                <div className="max-w-[600px] md:selection:border-l border-gray-500">
                                    <Accordion collapseAll className="md:w-[500px] mx-4">
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">What Benda Infotech does?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">
                                                    We provide a skills assessment platform that evaluates candidates across industries, ensuring the right fit for any role. Our tools streamline hiring, making it faster and more efficient for businesses to find top talent.
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
            
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">What types of skill assessments do you offer?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">
                                                    At Benda Infotech, we offer a wide range of skill assessments tailored to various roles and industries.
                                                    <br /><a className="hover:underline text-blue-600" href="/selectSkill">See all available skill tests.</a>
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
            
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">How much it take time to get placed?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">The time it takes to get placed through Benda Infotech varies depending on factors like the job market, your skillset, and the specific industry you're targeting. After completing our skill assessments, placement can range from a few weeks to a few months based on employer demand and interview processes. We work to expedite the process by connecting you with relevant opportunities and ensuring you're job-ready.
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
            
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">How soon will I know my results after the assessment?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">
                                                    You will receive your results immediately after the assessment in the form of a downloadable PDF report.
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
            
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">Are the assessments recognized by employers?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">
                                                    Yes, our assessments are designed to meet industry standards and are widely recognized by employers, helping you stand out during the hiring process.
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
            
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">Do you offer interview preparation or coaching?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">
                                                    Yes, we provide interview preparation and coaching to help you perform confidently and effectively during the hiring process.
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
            
                                        <Accordion.Panel>
                                            <Accordion.Title className="h-12 text-sm">What support do you offer during the job application process?</Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-gray-500 dark:text-gray-400 text-xs">
                                                    We offer personalized guidance throughout the job application process, including resume reviews, interview coaching, and matching you with relevant job opportunities based on your skills and assessments.
                                                </p>
            
                                            </Accordion.Content>
                                        </Accordion.Panel>
                                    </Accordion>
            
                                </div>
                            </div> */}

        {/* Testimonials */}
        {/* <div className="w-screen flex justify-center bg-customDarkBlue my-14">
                                <div className="h-auto w-full width-container flex flex-col item-center justify-center">
                                    <h2 className="text-center text-4xl text-white opacity-80 my-6">Hear from Our Candidates</h2>
                                    <div className="h-[560px] width-container w-3/4 mx-auto">
            
                                        <Carousel pauseOnHover
                                            leftControl={<IoIosArrowDropleft className="h-8 w-8 text-gray-300 opacity-25" />}
                                            rightControl={<IoIosArrowDropright className="h-8 w-8 text-gray-300 opacity-25" />}
                                        >
            
                                            {testimonialData.map((testimonial, index) => (
                                                <div key={index} className="h-full w-3/4 flex justify-center items-center">
                                                    <div className="mt-6 mx-24 mb-6 h-auto w-full flex flex-col items-center justify-center border border-cyan-500 rounded-xl shadow-lg shadow-sky-700 bg-gradient-to-r bg-white bg-opacity-25">
                                                        <img className="w-28 h-28 rounded-full -mt-14 border-2 border-cyan-500 shadow-lg shadow-gray-800 object-cover" src={testimonial.image} alt="..." />
                                                        <div>
                                                            <p className="text-base font-bold mt-3 pt-2 text-sky-400 text-center">{testimonial.name}<br /></p>
                                                            {(testimonial.designation || testimonial.company) && <p className="text-gray-200 text-[10px] text-center border-t">{testimonial.designation && testimonial.designation}   {testimonial.company && testimonial.company}</p>}
                                                        </div>
            
                                                        <div className="my-5 mx-20 inline-block text-center">
                                                            <span className="inline-block align-middle">
                                                                <ImQuotesLeft size={30} className="mr-1 text-sky-500" />
                                                            </span>
                                                            <p className="inline text-gray-100 text-xs align-middle">
                                                                {testimonial.comment}
                                                            </p>
                                                            <span className="inline-block align-middle">
                                                                <ImQuotesRight size={30} className="ml-1 text-sky-500" />
                                                            </span>
                                                        </div>
                                                    </div>
            
                                                </div>
                                            ))}
            
                                        </Carousel>
                                    </div>
            
                                </div >
                            </div> */}

        {/* Footer Promo */}
        {/* < div className="w-full width-container relative overflow-hidden mx-2 my-4 md:h-72 bg-yellow-50 rounded-lg shadow-lg bg-cover bg-center" style={{ backgroundImage: "url('./images/footer-promo.svg')" }
                            }>
                                <div className="absolute inset-0 flex flex-col items-center justify-center bg-opacity-50">
                                    <h2 className="text-5xl font-bold text-white">Show your capabilities</h2>
                                    <h3 className="text-4xl font-semibold text-white">to the recruitment team</h3>
                                    <p className="text-base text-gray-200">Choose a test which matches your skills</p>
                                    <button className="px-4 py-2 text-white rounded-full border border-white mt-4 hover:bg-white hover:text-gray-900">
                                        View available tests
                                    </button>
                                </div>
            
                            </div > */}
      </div>
    </>
  );
}
