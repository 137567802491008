import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSignOut } from '../Redux/user/userSlice.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HiDotsHorizontal } from 'react-icons/hi';
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavbarVisibility } from './NavbarVisibilityContext.jsx';
import { removePreTestData } from '../Redux/preTest/preTestSlice.js';
import { removeSkillTestData } from '../Redux/skillTest/skillTestSlice.js';
import { removeSelectedSkill } from '../Redux/selectSkill/selectSkillSlice.js';
import { Avatar, Button, Dropdown, Navbar } from 'flowbite-react';
import { removeRecruiterInfo } from '../Redux/recruiterSlice.js';
import { FaRegUser } from 'react-icons/fa';
import WindowLogin from '../pages/userAuth/Signin/WindowSignin.jsx';

export default function Header() {

    const { currentUser } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const boxRef = useRef(null);

    const path = useLocation().pathname;

    const { isNavbarVisible } = useNavbarVisibility();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isBoxVisible, setIsBoxVisible] = useState(false);

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        const handleScroll = () => setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setIsBoxVisible(false);
            }
        };

        if (isBoxVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isBoxVisible]);



    if (!isNavbarVisible) return null;

    const handleTakeTest = () => navigate('/selectSkill');
    const handleSignout = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/signout`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
            if (res.ok) {
                dispatch(userSignOut());
                dispatch(removePreTestData());
                dispatch(removeSkillTestData());
                dispatch(removeSelectedSkill());
                dispatch(removeRecruiterInfo());
                navigate('/signin');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const toggleBox = (e) => {
        e.stopPropagation(); // Prevent this event from triggering the parent `onClick`
        setIsBoxVisible(!isBoxVisible);
    };





    return (
        <Navbar
            className={`lg:h-16 sticky z-30 top-0 start-0 w-screen flex justify-center items-center
            ${(scrollPosition === 0 && path === '/') ? 'navbar-transparent' : 'bg-blue-700'}`}
            onClick={() => setIsBoxVisible(false)} // This will hide the box when clicking anywhere in the header
        >
            <div className="flex flex-wrap items-center justify-between w-full mx-auto py-4 width-container">
                <div className='flex gap-4 items-center'>
                    <Navbar.Collapse>
                        <Navbar.Link active={path === '/skillTestInstruction'} as="div">
                            <Link className="text-white font-josefinSans uppercase text-xs hover:text-gray-300" to="/how-it-works">How it works</Link>
                        </Navbar.Link>
                        <Navbar.Link active={path === '/viewPlans'} as="div">
                            <Link className="text-white font-josefinSans uppercase text-xs hover:text-gray-300" to="/viewPlans">Pricing</Link>
                        </Navbar.Link>
                        <Navbar.Link active={path === '/about'} as="div">
                            <Link className="text-white font-josefinSans uppercase text-xs hover:text-gray-300" to="/about">About</Link>
                        </Navbar.Link>
                    </Navbar.Collapse>
                    <div className="relative inline-block text-left">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center justify-center p-2 text-white bg-white bg-opacity-15 rounded-full focus:outline-none"
                        >
                            <HiDotsHorizontal size={15} />
                        </button>
                        {isOpen && (
                            <div className="absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <a href="/blog" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Blog</a>
                                    <a href="/talk-to-expert" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Talk to an expert</a>
                                    <a href="#/action-3" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Support</a>
                                    <a href="#/action-3" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Referral Program</a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full md:w-[628px] flex justify-between">
                    <a href="/" className="flex items-center">
                        <img src="./images/logo-white.png" className="h-10" alt="horg Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap text-white"></span>
                    </a>
                    <div className="flex items-center justify-center gap-3 md:order-2 space-x-1 md:space-x-2">

                        <button
                            onClick={handleTakeTest}
                            className="group font-josefinSans text-blue-800 text-sm px-4 mr-5 py-2 bg-white rounded-sm relative overflow-hidden hover:bg-gray-200"
                        >
                            Take a Test
                            <RiArrowRightSLine
                                size={20}
                                className="text-blue-800 mb-1 mx-2 inline-block transform transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                            />
                        </button>

                        {currentUser ? (
                            <Dropdown arrowIcon={false} inline label={<Avatar alt="user" img={currentUser.profilePicture} rounded />}>
                                <Dropdown.Header>
                                    <span className="block text-sm">{currentUser.name}</span>
                                    <span className="block text-xs font-medium truncate">{currentUser.email}</span>
                                </Dropdown.Header>
                                <Link to={currentUser.isRecruiter ? '/dashboard?tab=recruiterProfile' : '/dashboard?tab=profile'}>
                                    <Dropdown.Item>Profile</Dropdown.Item>
                                </Link>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleSignout}>Sign Out</Dropdown.Item>
                            </Dropdown>
                        ) : (
                            <div className='flex flex-row gap-2' onClick={(e) => e.stopPropagation()}>
                                {/* {path === '/' &&
                                    } */}
                                <div className="relative" ref={boxRef}>
                                    <button onClick={toggleBox} className="flex flex-col items-center">
                                        <FaRegUser size={20} color="white" className="p-2 h-full w-full" />
                                        <span className="text-[11px] text-white">Sign In</span>
                                    </button>
                                    {isBoxVisible && (
                                        <div
                                            className={`absolute mt-2 right-0 top-14 p-4 bg-gray-700 rounded-md shadow-lg
                                                        transition-opacity w-80 h-auto duration-300 ease-in-out transform
                                                        ${isBoxVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2'}`}
                                        >
                                            <WindowLogin />
                                            {/* Additional content can go here */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <Navbar.Toggle />
                    </div>
                </div>
            </div>
        </Navbar>
    );
}