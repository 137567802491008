import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import screenfull from "screenfull";
import Fuse from 'fuse.js';
import stringSimilarity from 'string-similarity';

import { useRef } from 'react';
import * as tf from '@tensorflow/tfjs';
import { createDetector, SupportedModels } from '@tensorflow-models/face-detection';

import { useNavbarVisibility } from "../components/NavbarVisibilityContext.jsx";
import { useFooterVisibility } from "../components/FooterVisibilityContext.jsx";

import { Button, Modal, Spinner } from "flowbite-react";
import { ClipLoader } from 'react-spinners';
import { SiWindowsterminal } from "react-icons/si";
import { BiMath, BiSelectMultiple } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import { MdReport } from 'react-icons/md';
import EndTest from "../components/EndTest";

//------------------------------------- language Option ----------
import CodeMirror, { Facet } from "@uiw/react-codemirror";
import { oneDark } from "@codemirror/theme-one-dark";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { java } from "@codemirror/lang-java";
import { xml } from "@codemirror/lang-xml";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { markdown } from "@codemirror/lang-markdown";
import { php } from "@codemirror/lang-php";
import { sql } from "@codemirror/lang-sql";
import { cpp } from "@codemirror/lang-cpp";
import { useSelector, useDispatch } from "react-redux";
import { removePreTestData } from "../Redux/preTest/preTestSlice.js";
import {
    addMarks,
    decreaseMcqRightAnswerCount,
    increaseCodingRightCount,
    increaseMcqRightAnswerCount,
    increaseViolations,
    removeSkillTestData,
    saveAndNextSuccess,
    saveTestQuestions,
    saveTestQuestionSuccess,
    setSubjects,
    startSkillTest,
    submitSkillTest,
} from "../Redux/skillTest/skillTestSlice.js";
//--------------------------------------------

export default function SkillTest() {


    const { setIsNavbarVisible, isNavbarVisible } = useNavbarVisibility();
    const { setIsFooterVisible } = useFooterVisibility();
    const { currentUser } = useSelector((state) => state.user);
    const { preTest } = useSelector((state) => state.preTest);
    const { marksObtained, questions, answers, loading, error, mcqRightAnswerCount, subject, codingRightAnswerCount, Violations } = useSelector((state) => state.skillTest);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [code, setCode] = useState(``);
    const [submitOutput, setSubmitOutput] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [language, setLanguage] = useState('');
    const [runError, setRunError] = useState('');
    const [compileLoading, setCompileLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);



    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const [isLookingAway, setIsLookingAway] = useState(false);
    const [outOfFrame, setOutOfFrame] = useState(false);
    const [violationCount, setViolationCount] = useState(0);
    let frameSkip = 0;
    let violationTimeout;
    let lookingAwayFrames = 0;
    let violationThreshold = 15;



    const [mcqQuestions, setMcqQuestions] = useState([]);
    const [subjectSummary, setSubjectSummary] = useState(null);
    const [codingQuestions, setCodingQuestions] = useState(null);
    const [allQuestion, setAllQuestion] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [timeLeft, setTimeLeft] = useState(preTest?.time ? preTest.time * 60 : 0);
    const [startTime, setStartTime] = useState(null);
    const [compileOutput, setcompileOutput] = useState("");
    const [compileColor, setcompileColor] = useState("");
    let [attemptedQusetion, setAttemptedQuestion] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [reportedQuestions, setReportedQuestions] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [reportMessage, setReportMessage] = useState("");
    const [isReported, setIsReported] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const answered = answers.find((i) => questions[currentQuestionIndex]._id === i.qID);



    //for viewing result in new window
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    // Supported Languague
    const languageExtensions = {
        javascript: javascript(),
        python: python(),
        java: java(),
        xml: xml(),
        html: html(),
        css: css(),
        markdown: markdown(),
        php: php(),
        sql: sql(),
        cpp: cpp(),
    };



    //handle prev question
    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    //handle next question
    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        // if (currentQuestionIndex === questions.length - 1) {
        //     setCurrentQuestionIndex(0);
        // }
    };

    //Handle exit full screen
    const handleExitFull = (event) => {
        if (screenfull.isFullscreen) {
            screenfull.exit();
        }
    };


    //------------------hnadle icon click-----------------------------------------
    const handleQuestionclick = (index) => {
        {
            setCurrentQuestionIndex(index);
        };

    }



    //------------------------------------report A QUESTION-----------------------------------
    const reportQuestion = async () => {
        try {
            if (questions[currentQuestionIndex].type === 'mcq') {
                const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/mcq/reportQuestion/${questions[currentQuestionIndex]._id}`,
                    {
                        method: "PUT",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );
                if (res.ok) {
                    console.log("mcq reported")
                }
            } else {
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/coding/reportQuestion/${questions[currentQuestionIndex]._id}`,
                    {
                        method: "PUT",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );
                if (res.ok) {
                    console.log("coding Q reported.")
                }
            }

        } catch (error) {
            console.error("Error reporting question:", error);
        }
    };

    const handleReport = async () => {
        const questionId = questions[currentQuestionIndex]._id; // Get current question ID

        try {
            await reportQuestion(); // Assuming this is your async report function
            setReportedQuestions(prevState => ({
                ...prevState,
                [questionId]: true // Mark this question as reported
            }));
            setReportMessage("Reported"); // Set success message
            setOpenModal(false);     // Close modal after reporting
        } catch (error) {
            console.error(error);    // Handle errors
        }
    };



    // ---------------- HANDLE SUBMIT --------------------------
    const handleSubmit = useCallback(async () => {
        if (isSubmitting || isSubmitted) return; // Prevent multiple submissions
        setIsSubmitting(true); // Mark as submitting
        const elapsedTimeInMillis = Date.now() - startTime;
        const minutes = Math.floor(elapsedTimeInMillis / 60000);
        const seconds = Math.floor((elapsedTimeInMillis % 60000) / 1000);
        const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        const testData = {
            userId: currentUser._id,
            name: currentUser.name,
            category: preTest.language,
            email: currentUser.email,
            level: preTest.level,
            questions: [...questions],
            answers: [...answers],
            subjectSummary: subjectSummary,
            marksObtained,
            fullMarks: preTest.fullMarks,
            numOfCoding: preTest.numOfCoding,
            numOfMcq: preTest.numOfMCQ,
            timeTaken: formattedTime,
            fullTime: preTest.time,
            rightMCQs: mcqRightAnswerCount,
            rightCodings: codingRightAnswerCount,
        };
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/test/saveTest`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(testData)
            });
            if (res.ok) {
                setIsSubmitted(true); // Mark as successfully submitted
                dispatch(setSubjects(subjectSummary));
                dispatch(submitSkillTest(formattedTime));
                handleExitFull();
                setIsNavbarVisible(true);
                setIsFooterVisible(true);
                setIsSubmitting(false); // Reset submitting flag
            }
        } catch (error) {
            console.log(error);
            setIsSubmitting(false); // Reset flag on error
        }
    }, [isSubmitting, isSubmitted, currentUser, preTest, questions, answers, marksObtained, mcqRightAnswerCount, codingRightAnswerCount]);


    // ----------------------------- get MCQ for Business-Intelligence ----------------------
    const getMcqForBusinessIntelligence = async () => {
        if (preTest.language === 'businessIntelligence') {
            try {
                const halfQuestions = Math.floor(preTest.numOfMCQ / 2);
                const remainingQuestion = preTest.numOfMCQ - halfQuestions;

                const resBusiness = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/mcq/getQuestions?language=businessIntelligence&numOfQuestion=${halfQuestions}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (resBusiness.ok) {
                    const dataBusiness = await resBusiness.json();
                    setMcqQuestions([...mcqQuestions, ...dataBusiness.mcqQuestions]);
                    setAllQuestion([...allQuestion, ...dataBusiness.mcqQuestions]);
                    dispatch(saveTestQuestions(dataBusiness.mcqQuestions));
                }


                const resBusinessAnalyst = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/mcq/getQuestions?language=businessAnalyst&numOfQuestion=${remainingQuestion}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (resBusinessAnalyst.ok) {
                    const data = await resBusinessAnalyst.json();
                    setMcqQuestions([...mcqQuestions, ...data.mcqQuestions]);
                    setAllQuestion([...allQuestion, ...data.mcqQuestions]);
                    dispatch(saveTestQuestions(data.mcqQuestions));
                }

                dispatch(saveTestQuestionSuccess());

            } catch (error) {
                console.log(error);
            }
        }
    }



    // -----------------------------get Mcq for BusinessAnalyst ---------------------------
    const getMcqForBusinessAnalyst = async () => {
        if (preTest.language === 'businessAnalyst') {
            try {
                const halfQuestions = Math.floor(preTest.numOfMCQ / 2);
                const remainingQuestion = preTest.numOfMCQ - halfQuestions;

                const resBusiness = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/mcq/getQuestions?language=businessAnalyst&numOfQuestion=${halfQuestions}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (resBusiness.ok) {
                    const dataBusiness = await resBusiness.json();
                    setMcqQuestions([...mcqQuestions, ...dataBusiness.mcqQuestions]);
                    setAllQuestion([...allQuestion, ...dataBusiness.mcqQuestions]);
                    dispatch(saveTestQuestions(dataBusiness.mcqQuestions));
                }


                const resDataAnalyst = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/mcq/getQuestions?language=dataanalyst&numOfQuestion=${remainingQuestion}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (resDataAnalyst.ok) {
                    const data = await resDataAnalyst.json();
                    setMcqQuestions([...mcqQuestions, ...data.mcqQuestions]);
                    setAllQuestion([...allQuestion, ...data.mcqQuestions]);
                    dispatch(saveTestQuestions(data.mcqQuestions));
                }

                dispatch(saveTestQuestionSuccess());

            } catch (error) {
                console.log(error);
            }
        }
    }



    //------------------------------ get Mcq questions -------------------------------------
    const getMcqQ = async () => {
        if (preTest.numOfMCQ && preTest.language !== 'businessAnalyst' && preTest.language !== 'businessIntelligence') {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/mcq/getQuestions?language=${preTest.language}&numOfQuestion=${preTest.numOfMCQ}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (res.ok) {
                    const data = await res.json();
                    setMcqQuestions(data.mcqQuestions);
                    setAllQuestion(data.mcqQuestions);
                    dispatch(saveTestQuestions(data.mcqQuestions));
                    dispatch(saveTestQuestionSuccess());
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    //------------------------------ get Coding questions ---------------------------------------
    const getCodingQ = async () => {
        if (preTest.numOfCoding && preTest.numOfCoding >= 0) {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND}/api/coding/getQuestions?language=${preTest.language === 'dataanalyst' ? 'python' : preTest.language}&numOfQuestion=${preTest.numOfCoding}&level=${preTest.language === 'java' ? preTest.level : "easy"}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                if (res.ok) {
                    const data = await res.json();
                    setCodingQuestions(data.codingQuestions);
                    setAllQuestion([...allQuestion, ...data.codingQuestions])
                    dispatch(saveTestQuestions(data.codingQuestions));

                    dispatch(saveTestQuestionSuccess());
                }
            } catch (error) {
                console.log(error);
            }
        }
    };


    const setQ = async () => {

        await getMcqQ();
        await getMcqForBusinessAnalyst();
        await getMcqForBusinessIntelligence();
        await getCodingQ();
    }

    //--------------------- subject summary function ----------------
    const handleSubjectSummary = () => {
        let newSubjectSummery = [];
        const filteredMcqQuestions = questions.filter(q => q.type === 'mcq');


        filteredMcqQuestions.forEach((question) => {
            const existingSubject = newSubjectSummery.find(sub => sub.sub === question.subject);

            if (existingSubject) {
                existingSubject.num += 1;
            } else {
                newSubjectSummery.push({ sub: question.subject, num: 1, right: 0 });
            }
        });

        // Update the state with the new subject summary
        setSubjectSummary(newSubjectSummery);
    }


    //
    useEffect(() => {
        dispatch(removeSkillTestData());
        if (!preTest) {
            navigate("/testOptions");
        } else if (!preTest.mediaAccess) {
            navigate('/testInstruction')
        } else {
            // Enter fullscreen mode
            dispatch(startSkillTest());
            if (screenfull.isEnabled) {
                screenfull.request();
                setIsNavbarVisible(false);
                setIsFooterVisible(false);
            }

            setLanguage(preTest.language === 'dataanalyst' ? 'python' : preTest.language);
            setQ();
            setStartTime(Date.now());

            // dispatch(saveTestQuestions(allQuestion));
            // setCurrentQuestion(questions[currentQuestionIndex]);
        }
    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            handleSubjectSummary(); // Executes after questions are set
        }
    }, [questions]);



    //----------------------------- question Index change useEffect -----------------------
    useEffect(() => {
        const currentQuestion = questions[currentQuestionIndex];
        if (currentQuestion && currentQuestion.type === "coding") {
            const existingCode = answers.find((i) => currentQuestion._id === i.qID);
            setSubmitOutput('');
            if (existingCode) {
                setCode(existingCode.answer);
                setcompileOutput('')
                setcompileColor('');
            } else {
                setCode(currentQuestion.predefine);
                setcompileOutput('')
                setcompileColor('');
            }
            // Load predefined code if available
        };

        // if(answers.length > -1){
        //     for(let a of answers){
        //         if(a.answer !== ""){
        //             setAttemptedQuestion(attemptedQusetion + 1);
        //         }
        //     }
        // }

        if (answers.length > -1) {
            const numOfAttemptedQ = answers.filter((a) => a.answer !== "");
            setAttemptedQuestion(numOfAttemptedQ.length)
        }

        const answered = answers.find((i) => questions[currentQuestionIndex]._id === i.qID);
        if (answered && answered.type === 'mcq') {
            setSelectedOption(answered.answer);
        } else {
            setSelectedOption('');
        }

    }, [currentQuestionIndex, questions]);


    // ---------------------Update the reported status whenever the question changes-----------------------
    useEffect(() => {
        const questionId = questions[currentQuestionIndex]?._id;
        if (questionId) {
            setIsReported(!!reportedQuestions[questionId]); // Check if the current question is reported
        }
    }, [currentQuestionIndex, reportedQuestions, questions]);



    // onChange the
    const handleCodeChange = (newValue) => {
        setCode(newValue);
    };

    //-------------------------------------- Handle Evaluation ----------------------------
    const handleEvaluation = (str1, str2) => {


        const FUSE_WEIGHT = 0.3;
        const SIMILARITY_WEIGHT = 0.7;

        // Create a list containing the target string for Fuse.js
        const list = [str2];

        // Initialize Fuse.js
        const fuse = new Fuse(list, {
            includeScore: true,
            threshold: 0.7, // Adjust as needed
        });

        // Perform fuzzy matching
        const fuseResult = fuse.search(str1);

        // Extract the Fuse.js score (0 to 1)
        const fuseScore = fuseResult.length > 0 ? fuseResult[0].score : 1;

        // Calculate string similarity score (0 to 1)
        const similarityScore = stringSimilarity.compareTwoStrings(str1, str2);

        // Normalize scores
        const normalizedFuseScore = 1 - fuseScore; // Convert to 1 - score for similarity
        const finalScore = (normalizedFuseScore * FUSE_WEIGHT) + (similarityScore * SIMILARITY_WEIGHT);

        return finalScore * 100;
    }


    // --------------------------- GENERATE SCORE function according to the matching percentage ------------------------
    const handleGenarateScore = async () => {
        let totalMarks = marksObtained;
        const predefineMarks = questions[currentQuestionIndex].marks;
        const predefineAnswer = questions[currentQuestionIndex].answer;
        const answeredQuestion = answers.find((i) => questions[currentQuestionIndex]._id === i.qID);

        const matchPercentage = handleEvaluation(predefineAnswer, questions[currentQuestionIndex].type === 'coding' ? submitOutput : selectedOption);
        console.log(`Match-Percentage::${matchPercentage}`);

        // // Deduction flag to ensure only one-time deduction per question
        // let deductionApplied = answeredQuestion?.deductionApplied || false;
        // // If answered question exists and no deduction has been applied yet
        // if (answeredQuestion && !deductionApplied) {
        //     const previousAnswer = answeredQuestion.answer;
        //     const previousMatchPercentage = handleEvaluation(predefineAnswer, previousAnswer);
        //     // Handling MCQ type questions
        //     if (answeredQuestion.type === 'mcq') {
        //         // If the previous answer was correct and the new answer is incorrect
        //         if (previousMatchPercentage >= 98 && matchPercentage < 98) {
        //             totalMarks -= predefineMarks;
        //             dispatch(decreaseMcqRightAnswerCount());
        //             deductionApplied = true;
        //         }
        //     }
        // }


        // If the previous answer was correct, and the new answer is incorrect, reduce the marks
        // if (answeredQuestion && answeredQuestion.type === 'coding') {
        //     const previousAnswer = answeredQuestion.answer;
        //     const previousMatchPercentage = handleEvaluation(predefineAnswer, previousAnswer);
        //     if (previousMatchPercentage >= 90 && matchPercentage < 80) {
        //         totalMarks = totalMarks - (.25 * predefineMarks);
        //     }
        //     else if (previousMatchPercentage >= 80 && matchPercentage < 70) {
        //         totalMarks = totalMarks - (.50 * predefineMarks);
        //     }

        //     else {
        //         totalMarks = totalMarks - predefineMarks;
        //     }

        // }

        if (questions[currentQuestionIndex].type === 'coding') {
            if (matchPercentage >= 90) {
                totalMarks = totalMarks + predefineMarks;
                dispatch(increaseCodingRightCount());
            } else if (matchPercentage >= 80) {
                totalMarks = totalMarks + (.75 * predefineMarks);
                dispatch(increaseCodingRightCount());
            } else if (matchPercentage >= 70) {
                totalMarks = totalMarks + (.50 * predefineMarks)
            } else {
                totalMarks = totalMarks;
            }
        } else {
            if (matchPercentage >= 98) {
                totalMarks = totalMarks + predefineMarks;
                dispatch(increaseMcqRightAnswerCount());
            }
        }

        totalMarks = Math.max(0, totalMarks);
        dispatch(addMarks(totalMarks))
    }

    //--------------------------------------- Handle Run/Compile Code -----------------------------
    // const handleRun = async () => {
    //     const apiUrl = 'https://emkc.org/api/v2/piston/execute';

    //     const requestBody = {
    //         language: preTest.language === 'java' ? 'java' :
    //             preTest.language === 'dataanalyst' ? 'python' : preTest.language,
    //         version: preTest.language === "java" ? "15.0.2" : preTest.language === 'dataanalyst' ? "3.10.0" : '15.0.2',
    //         files: [
    //             {
    //                 name: `Main.${preTest.language === 'java' ? 'java' :
    //                     preTest.language === 'dataanalyst' ? 'py' : preTest.language}`,
    //                 content: code
    //             }
    //         ]
    //     };

    //     try {
    //         setCompileLoading(true);
    //         const response = await axios.post(apiUrl, requestBody, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         setSubmitOutput(response.data.run.output);
    //         setRunError('');
    //         setCompileLoading(false);
    //     } catch (err) {
    //         setRunError(err.response ? err.response.data : err.message);
    //         setSubmitOutput('');
    //     }
    // };
    const handleRun = async () => {
        const apiUrl = "https://emkc.org/api/v2/piston/execute";
        const requestBody = {
            language:
                preTest.language === "java"
                    ? "java"
                    : preTest.language === "dataanalyst"
                        ? "python"
                        : preTest.language,
            version:
                preTest.language === "java"
                    ? "15.0.2"
                    : preTest.language === "dataanalyst"
                        ? "3.10.0"
                        : "15.0.2",
            files: [
                {
                    name: `Main.${preTest.language === "java"
                        ? "java"
                        : preTest.language === "dataanalyst"
                            ? "py"
                            : preTest.language
                        }`,
                    content: code,
                },
            ],
        };
        try {
            setCompileLoading(true);
            const response = await axios.post(apiUrl, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const output = response.data.run.output;
            setSubmitOutput(output);
            setRunError("");
            setCompileLoading(false);
            const predefineAnswer = questions[currentQuestionIndex].answer;
            const matchPercentage = handleEvaluation(
                predefineAnswer,
                questions[currentQuestionIndex].type === "coding" && output
            );
            // Output evaluation logic based on match percentage
            if (matchPercentage >= 90) {
                setcompileOutput("Your output matched 100%, Hit the Save and next button");
                setcompileColor("green");
            } else if (matchPercentage >= 80) {
                setcompileOutput("Your output matched 85%. Try again.");
                setcompileColor("blue");
            } else if (matchPercentage >= 70) {
                setcompileOutput("Your output matched 75%. Try to improve it.");
                setcompileColor("blue");
            } else {
                setcompileOutput("Your output did not match. Try to improve it.");
                setcompileColor("red");
            }
            console.log(matchPercentage);
        } catch (err) {
            setRunError(err.response ? err.response.data : err.message);
            setSubmitOutput("");
            setCompileLoading(false);
        }
    };


    //------------------------------------- Handle Save & Next -------------------------------
    const handleSaveAndNext = async () => {
        setSaveLoading(true);
        const cQuestion = questions[currentQuestionIndex];

        if (cQuestion.type === 'coding') {
            await handleRun();
        }

        const matchPercentage = await handleEvaluation(cQuestion.answer, cQuestion === 'coding' ? code : selectedOption);

        try {
            const answerData = {

                qID: cQuestion._id,
                question: cQuestion.question,
                options: cQuestion.type === 'mcq' ? cQuestion.options : null,
                answer: cQuestion.answer,
                givenAnswer: cQuestion.type === 'coding' ? code : selectedOption,
                language: cQuestion.language,
                subject: cQuestion.type === 'mcq' ? cQuestion.subject : null,
                fullMarks: cQuestion.marks,
                matchPercentage: matchPercentage,
                type: cQuestion.type,
                level: cQuestion.type === 'coding' ? cQuestion.level : 'null',
            }

            // Update subjectSummary if current question is MCQ and answer is correct
            if (cQuestion.type === 'mcq' && cQuestion.answer === selectedOption) {
                setSubjectSummary((prevSummary) => {
                    // Create a new array with updated values
                    const updatedSummary = prevSummary.map((subSummary) => {
                        if (subSummary.sub === cQuestion.subject) {
                            // Increment right only if it's less than num
                            return {
                                ...subSummary,
                                right: Math.min(subSummary.right + 1, subSummary.num),
                            };
                        }
                        return subSummary;
                    });
                    return updatedSummary;
                });
            }

            dispatch(saveAndNextSuccess(answerData));
            handleNextQuestion();
            await handleGenarateScore();


            // setSubmitOutput('');

        } catch (error) {
            console.log(error);
        } finally {
            setSaveLoading(false);
        }


    }

    // ---------------------------------------set countdown---------------------------------
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    handleSubmit(); // Trigger submit when time ends
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000); // Update every second
        return () => clearInterval(timer); // Cleanup on unmount
    }, [timeLeft]);


    const formatTime = (seconds) => {
        if (seconds === null || seconds === undefined) {
            return "00:00";
        }
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDivClick = (option) => {
        setSelectedOption(option);
    };
    //-------------------------------------------Handle reset code-----------------------
    const handleReset = () => {
        setCode(questions[currentQuestionIndex].predefine);
    };


    // -------------------------------- Submitting if the User Exit FullScreen mode -------------------
    useEffect(() => {
        // Function to handle the fullscreen change
        const handleFullscreenChange = () => {
            if (!isSubmitted) {
                if (!screenfull.isFullscreen) {
                    handleSubmit(); // Call the handleSubmit function when fullscreen mode is exited
                }
            }
        };

        // Add the fullscreen change listener
        if (screenfull.isEnabled) {
            screenfull.on('change', handleFullscreenChange);
        }

        // Cleanup function to remove the listener when the component is unmounted or dependencies change
        return () => {
            if (screenfull.isEnabled) {
                screenfull.off('change', handleFullscreenChange);
            }
        };
    }, [screenfull.isFullscreen]);


    //------------------------------------------------ Preventing Window Tab Switching ---------------------------------------------
    const handleBlur = useCallback(async () => {
        if (!isSubmitted && !isSubmitting) {
            // Warn user
            alert('You switched other windows or tabs! We have to end the test here. Thank You.');
            try {
                setIsSubmitting(true); // Set flag to prevent multiple submissions
                await handleSubmit();
            } catch (error) {
                console.error("Error during submission:", error);
            } finally {
                setIsSubmitting(false); // Reset flag after submission
            }
        }
    }, [isSubmitted, isSubmitting, handleSubmit]);

    useEffect(() => {
        window.addEventListener('blur', handleBlur);
        return () => {
            window.removeEventListener('blur', handleBlur);
        };
    }, [handleBlur]);

    useEffect(() => {
        if (isSubmitted) {
            openInNewTab("/initialResult"); // Only open this tab once after submission
        }
    }, [isSubmitted]);



    //------------------------------------------------Proctor-------------------------------------------
    // useEffect(() => {
    //     const loadModel = async () => {
    //       try {
    //         await tf.setBackend('webgl'); // Use WebGL backend for GPU acceleration
    //         await tf.ready();

    //         const detector = await createDetector(SupportedModels.MediaPipeFaceDetector, {
    //           runtime: 'tfjs',
    //           modelType: 'full', // Higher accuracy model
    //           maxFaces: 5,
    //         });

    //         startMonitoring(detector);
    //       } catch (error) {
    //         console.error('Error loading the TensorFlow model:', error);
    //       }
    //     };

    //     const startMonitoring = async (detector) => {
    //       try {
    //         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    //         streamRef.current = stream;
    //         const videoTrack = stream.getVideoTracks()[0];
    //         const imageCapture = new ImageCapture(videoTrack);

    //         const detectFaceInStream = async () => {
    //           if (!streamRef.current) return;

    //           try {
    //             if (frameSkip % 5 === 0) { // Skip frames to improve performance
    //               const bitmap = await imageCapture.grabFrame();
    //               const smallBitmap = await createImageBitmap(bitmap, { resizeWidth: 640, resizeHeight: 480 });

    //               const faces = await detector.estimateFaces(smallBitmap, { minConfidence: 0.8 }); // Higher confidence

    //               // Handle multiple faces detected
    //               if (faces.length >= 2) {
    //                 handleViolation();
    //                 drawCanvas(smallBitmap, faces, true); // Draw with red border for violation
    //               } else if (faces.length === 1) {
    //                 const { keypoints } = faces[0];
    //                 const leftEye = keypoints[0];
    //                 const rightEye = keypoints[1];

    //                 const eyeAngle = Math.abs(leftEye.y - rightEye.y); // Eye angle logic
    //                 const lookingAway = eyeAngle > 20; // Replace with appropriate threshold

    //                 if (lookingAway) {
    //                   lookingAwayFrames++;
    //                   if (lookingAwayFrames >= 5) { // Ensure multiple consecutive frames
    //                     handleViolation();
    //                   }
    //                   setIsLookingAway(true);
    //                 } else {
    //                   lookingAwayFrames = 0;
    //                   setIsLookingAway(false);
    //                 }

    //                 setOutOfFrame(false);
    //                 drawCanvas(smallBitmap, faces, false); // Draw with green border if no violation
    //               } else {
    //                 if (!outOfFrame) {
    //                   handleViolation(); // Trigger violation if face is out of frame
    //                   setOutOfFrame(true);
    //                 }
    //                 setIsLookingAway(false);
    //                 drawCanvas(smallBitmap, faces, true); // Draw with red border when out of frame
    //               }
    //             }

    //             frameSkip++;
    //             requestAnimationFrame(detectFaceInStream);
    //           } catch (error) {
    //             console.error('Error in face detection or frame grabbing:', error);
    //           }
    //         };

    //         detectFaceInStream();
    //       } catch (error) {
    //         console.error('Error accessing webcam: ', error);
    //         alert('Please allow webcam access to use this feature.');
    //       }
    //     };

    //     const handleViolation = () => {
    //       if (!violationTimeout) {
    //         violationTimeout = setTimeout(() => {
    //           setViolationCount((prev) => prev + 1); // Update violation count state
    //           dispatch(increaseViolations())
    //           violationTimeout = null;
    //         }, 1000); // Debounce to limit violation frequency
    //       }
    //     };

    //     const drawCanvas = (bitmap, faces, isViolation) => {
    //       const canvas = canvasRef.current;
    //       if (!canvas) return; // Check if the canvas is available
    //       const ctx = canvas.getContext('2d');
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);

    //       if (faces.length > 0) {
    //         faces.forEach((face) => {
    //           const { keypoints } = face;
    //           ctx.strokeStyle = isViolation ? 'red' : 'green';
    //           ctx.lineWidth = 4;

    //           // Draw bounding box around face
    //           const leftEye = keypoints[0];
    //           const rightEye = keypoints[1];
    //           ctx.strokeRect(leftEye.x - 50, leftEye.y - 50, rightEye.x - leftEye.x + 100, 100);
    //         });
    //       }
    //     };

    //     loadModel();

    //     return () => {
    //       if (streamRef.current) {
    //         const tracks = streamRef.current.getTracks();
    //         tracks.forEach((track) => track.stop());
    //       }
    //       clearTimeout(violationTimeout);
    //     };
    //   }, []);



    //-------------------------------------------------- BOSS IF --------------------------------------------------------------
    if (allQuestion.length <= 0) {
        return (<div className="flex items-center justify-center h-screen"><ClipLoader loading={loading} size={50} color="blue" /></div>)
    }


    return (
        <>
            {isSubmitted ? (
                <EndTest />
            ) : (<>
                {loading ? (<div className="flex items-center justify-center h-screen"><ClipLoader loading={loading} size={50} color="blue" /></div>) : (

                    <div className="h-screen flex flex-col">
                        {/* --------------------------------------TOP SECTION------------------------------- */}

                        <div className="flex flex-row items-center justify-between h-24 bg-blue-600 w-full shadow-md ">
                            <div className="ml-4">
                                <img className="w-28" alt="benda logo" src="./images/logo-white.png" />
                            </div>
                            <div>
                                <div className="flex flex-col items-center">
                                    <p className="text-gray-200 text-sm">Time left:</p>
                                    <p className="text-white text-sm">{formatTime(timeLeft)}</p>
                                </div>
                            </div>
                            <div className="mr-4">
                                <label htmlFor="language" className="text-white text-sm">
                                    Choose Language:{" "}
                                </label>
                                <select
                                    className="text-sm rounded-md bg-blue-400 text-gray-700 border-white w-auto"
                                    id="language"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                >
                                    <option value="javascript">JavaScript</option>
                                    <option value="python">Python</option>
                                    <option value="java">Java</option>
                                    <option value="xml">XML</option>
                                    <option value="html">HTML</option>
                                    <option value="css">CSS</option>
                                    <option value="markdown">Markdown</option>
                                    <option value="php">PHP</option>
                                    <option value="sql">SQL</option>
                                    <option value="cpp">C++</option>
                                    <option value="rust">Rust</option>
                                    <option value="json">JSON</option>
                                    <option value="yaml">YAML</option>
                                    <option value="shell">Shell</option>
                                </select>
                            </div>
                        </div>

                        {/* --------------------------------------MID SECTION-------------------------------- */}
                        <div className="h-screen flex flex-col md:flex-row mt-2">
                            {/* Question Div */}
                            <div>
                                <div className="h-auto md:h-[550px] overflow-y-auto scroll-auto">
                                    <div className="flex-grow h-auto px-4 overflow-y-auto scroll-auto  py-2 w-full md md:w-80 border-r">
                                        <div className="p-2 pb-4 border shadow-md rounded-lg mb-3">
                                            <div className="flex flex-row justify-between">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-800 border-b">
                                                    QUESTION
                                                </h5>

                                                {/* Report Button or Success Message */}
                                                {isReported ? (
                                                    <div className="flex flex-row gap-1">
                                                        <p className="text-green-600 text-xs">
                                                            {reportMessage}
                                                        </p>
                                                        <MdReport color="grey" size={20} />
                                                    </div>

                                                ) : (
                                                    <MdReport
                                                        color="red"
                                                        size={30}
                                                        className="cursor-pointer"
                                                        onClick={() => setOpenModal(true)}
                                                        aria-label="Report question"
                                                    />
                                                )}
                                            </div>
                                            <p className="select-none text-base mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                {questions[currentQuestionIndex]?.question}
                                            </p>
                                        </div>
                                        {questions[currentQuestionIndex].type === "coding" && (
                                            <>
                                                <p className="text-sm">
                                                    <span className=" font-extrabold text-xs">
                                                        INSTRUCTIONS:
                                                    </span>
                                                    <br></br>
                                                    {questions[currentQuestionIndex].instructions}
                                                    <br></br>(Please compile the code & check the output
                                                    before save and next)
                                                </p>
                                                <p className="text-sm mt-2">
                                                    <span className=" font-extrabold text-xs">
                                                        OUTPUT:
                                                    </span>
                                                    <br />
                                                    {questions[currentQuestionIndex].answer}
                                                </p>
                                                <p className="text-xs mt-4 text-red-600 font-bold">
                                                    *KEEP IN MIND:<br />
                                                    <span className="text-gray-800 text-xs font-normal">
                                                        Please do not touch or edit the predefined code in the editor.<br></br>Incase you made some changes in predefined code then use the reset button to retrieve the bolierplate code.
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                    </div>


                                </div>
                                <div className=" w-full max-w-80 flex flex-row mx-1 object-contain my-3 justify-between">
                                    <Button
                                        onClick={handlePreviousQuestion}
                                        className="rounded-full md:ml-3"
                                    >
                                        <FaArrowLeft />
                                    </Button>
                                    <Button onClick={handleNextQuestion} className="rounded-full md:mr-4">
                                        <FaArrowRight />
                                    </Button>
                                </div>
                            </div>

                            {/* --------------------- CodeSpace  Or MCQ-space------------------ */}

                            {questions[currentQuestionIndex].type === 'coding' ? (
                                <div className="w-full md:w-1/2 h-96 md:h-96">
                                    <CodeMirror
                                        className="codeMirror-custom mt-1 border-4 border-gray-500"
                                        value={code}
                                        height="550px"
                                        theme={oneDark}
                                        extensions={[languageExtensions[language]]}
                                        onChange={handleCodeChange}
                                        options={{
                                            lineNumbers: true,
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className='w-full mx-2 md:w-1/2 h-auto md:h-96'>
                                    <div className="w-full h-auto md:h-96 p-12 bg-gradient-to-r from-blue-900 to-blue-400 rounded-lg shadow-lg">
                                        {questions[currentQuestionIndex].options.map((option, index) => (
                                            <div key={index} className="mb-2 flex items-center justify-center">
                                                <input
                                                    type="radio"
                                                    value={option}
                                                    checked={selectedOption === option}
                                                    onChange={handleOptionChange}
                                                    className="form-radio text-indigo-600 h-4 w-4 transition duration-200 mr-2"
                                                    disabled={!!answered} // Disable if answered
                                                />
                                                <div
                                                    className={`p-2 rounded-lg bg-white hover:bg-gray-100 shadow-lg transform transition-all duration-300 hover:scale-95 w-full cursor-pointer ${answered ? "cursor-not-allowed" : ""}`}
                                                    onClick={() => !answered && handleDivClick(option)} // Prevent click if answered
                                                >
                                                    <span className="text-gray-800 text-sm">{option}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Output & Indexing */}

                            <div className="bg-white h-auto md:h-[580px] px-2 py-3 w-full md:w-1/4 overflow-x-auto">
                                {questions[currentQuestionIndex].type === 'coding' && (<div>
                                    <a href="#">
                                        <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
                                            OUTPUT
                                        </h5>
                                        <hr />
                                    </a>
                                    <div className="overflow-x-auto w-fit">
                                        <p class="mb-3 min-h-12 w-fit h-auto overflow-x-auto">
                                            {runError ? (<span className="text-red-700 text-xs">{runError}</span>) : submitOutput}
                                        </p>
                                    </div>
                                    <div>
                                        <hr />
                                        <p className={`text-${compileColor}-500 text-xs`}>{compileOutput}</p>
                                        <hr />
                                        <div className="flex flex-row justify-between">
                                            <button
                                                onClick={handleRun}
                                                type="button"
                                                class=" justify-center items-center mt-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                            >
                                                {compileLoading ? (<>
                                                    <Spinner size='sm' />
                                                    <span className='ml-2 text-sm'>Compiling...</span>
                                                </>) : 'Compile'}
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                type="button"
                                                class=" justify-center items-center mt-4 focus:outline-none text-white bg-gray-700 hover:bg-red-800 focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>)}
                                <h3 className="mt-6 p-2 border-2 border-blue-500 bg-blue-800 text-white rounded-md">
                                    Attempted questions: <b>{attemptedQusetion}</b>
                                </h3>
                                <div className="flex flex-row flex-wrap w-full h-auto object-contain mt-2">
                                    {questions.map((q, index) => {
                                        const isAnswered = answers.find((i) => q._id === i.qID);
                                        return (
                                            <div
                                                className={`m-1 p-2 ${currentQuestionIndex === index ? "border-2 border-cyan-500 rounded" : ""} ${isAnswered ? "cursor-pointer" : "cursor-pointer"}`}
                                                key={index}
                                                onClick={() => handleQuestionclick(index)}
                                            >
                                                {q.type === 'coding' ? (
                                                    <SiWindowsterminal
                                                        color={isAnswered ? 'green' : 'grey'}
                                                        size={20}
                                                    />
                                                ) : (
                                                    <BiSelectMultiple
                                                        color={isAnswered ? 'green' : 'grey'}
                                                        size={20}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                <button
                                    disabled={
                                        (questions[currentQuestionIndex].type === 'coding' && answered) ||  // Disable for coding if answered
                                        (questions[currentQuestionIndex].type === 'mcq' && answered) ||     // Disable for MCQ if answered
                                        (questions[currentQuestionIndex].type === 'mcq' && selectedOption === "")  // Disable for MCQ until an option is selected
                                    }
                                    onClick={handleSaveAndNext}
                                    type="button"
                                    className={`relative group justify-center items-center mt-4 focus:outline-none text-white
  ${(answered || (questions[currentQuestionIndex].type === 'mcq' && selectedOption === ""))
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300'}
  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`}
                                >
                                    {saveLoading ? (
                                        <>
                                            <Spinner size='sm' />
                                            <span className='ml-2 text-sm'>Saving...</span>
                                        </>
                                    ) : (
                                        // Button label logic
                                        currentQuestionIndex === questions.length - 1 ? (
                                            // Last question logic
                                            answered ? 'Already Submitted' : 'Save'
                                        ) : (
                                            // Non-last question logic
                                            answered ? 'Already Submitted' : 'Save and Next'
                                        )
                                    )}
                                    {/* Tooltip logic for MCQs */}
                                    {questions[currentQuestionIndex].type === 'mcq' && selectedOption === "" && !answered && (
                                        <span
                                            className="absolute invisible group-hover:visible bg-blue-200 text-black text-xs rounded py-1 px-2 top-full left-1/2 transform -translate-x-1/2 mt-1 whitespace-nowrap transition-opacity delay-500 duration-400 opacity-0 group-hover:opacity-100"
                                        >
                                            Please select an option
                                        </span>
                                    )}
                                </button>

                            </div>
                        </div>

                        {/* FOOTER SECTION */}
                        <div className="h-14 bg-slate-200 w-full flex flex-row justify-center">
                            <Button className="m-2 mr-16" color="blue" onClick={handleSubmit}>
                                {submitLoading ? 'Submitting...' : 'Submit & End Test'}
                            </Button>
                        </div>

                        {<Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                            <Modal.Header />
                            <Modal.Body>
                                <div className="text-center">
                                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                        Are you sure you want to report this question?
                                    </h3>
                                    <div className="flex justify-center gap-4">
                                        <Button color="failure" onClick={handleReport}>
                                            {"Yes, I'm sure"}
                                        </Button>
                                        <Button color="gray" onClick={() => setOpenModal(false)}>
                                            No, cancel
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>}

                    </div>
                )}
            </>
            )}
        </>
    );
}
