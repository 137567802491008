import React from 'react'
import TalkToExpertForm from '../components/TalkToExpertForm.jsx';
import contactUs from '../assets/contact-us.json';
import Lottie from 'lottie-react';

function TalkToExpert() {
    return (
        <div className='w-full'>
            <div className='w-full mx-auto max-w-[1140px] flex flex-col items-center'>
                <div className='flex flex-col items-center'>
                    <Lottie className='h-96' animationData={contactUs} ></Lottie>
                    <p className="w-1/2 text-sm text-gray-600 text-center -mt-12 mb-6">Fill out the form below with your details and query. Choose your preferred contact method, and our team will reach out to you as soon as possible.</p>
                </div>

            </div>
            <TalkToExpertForm />
        </div>
    )
}

export default TalkToExpert